import { StyledTableCell, StyledTableRow } from "components/templates/Tables";
import {
  Table,
  TableRow,
  TableBody,
  TableHead,
  TableContainer,
} from "@mui/material";
import { useAppSelector } from "redux/hooks";
import { useEffect } from "react";
import TableLoadingWrapper from "components/templates/TableLoadingWrapper";
import { Link } from "react-router-dom";
import BlockComponent from "components/organisms/BlockComponent/BlockComponent";
import PriorityService from "services/priority.service";

export default function PriorityList() {
  const loading = useAppSelector((state) => state.priority.loading);
  const priorities = useAppSelector((state) => state.priority.priorities);

  useEffect(() => {
    PriorityService.getAll();
  }, []);

  return (
    <TableContainer>
      <Table
        aria-label="customized table"
        sx={{
          minWidth: "100%",
          borderSpacing: "0 10px",
          borderBottomWidth: "0px",
          borderCollapse: "separate",
        }}
      >
        <TableLoadingWrapper
          coloumns={6}
          loading={loading}
          message="There is no priority added yet"
          length={priorities.length}
        >
          <TableHead>
            <TableRow>
              <StyledTableCell>Title</StyledTableCell>
              <StyledTableCell>Level</StyledTableCell>
              <StyledTableCell>Expiry(Minutes)</StyledTableCell>

              <BlockComponent permission={"priority:write"}>
                <StyledTableCell align="center">Action</StyledTableCell>
              </BlockComponent>
            </TableRow>
          </TableHead>
          <TableBody>
            {priorities.map((priority) => (
              <StyledTableRow key={priority._id}>
                <StyledTableCell>{priority.title}</StyledTableCell>
                <StyledTableCell>{priority.level}</StyledTableCell>
                <StyledTableCell>{priority.expiryInMinutes}</StyledTableCell>

                <StyledTableCell align="center">
                  <BlockComponent permission={"priority:write"}>
                    <Link
                      className="detail-link"
                      to={`/update-priority/${priority._id}`}
                    >
                      Details
                    </Link>
                  </BlockComponent>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </TableLoadingWrapper>
      </Table>
    </TableContainer>
  );
}
