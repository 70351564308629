import { modalActions } from './../redux/slices/modal/modalSlice';
import { productActions } from './../redux/slices/product/productSlice';
import http from "./http.service";
import Promisable from "./promisable.service";
import { NavigateFunction } from "react-router-dom";
import { getAppDispatch } from "utils/dispatch.util";


const url = 'products'
const productService = {
    addProduct: async (data: any, navigate?: NavigateFunction) => {

		const dispatch = getAppDispatch();
		dispatch?.(productActions.setLoading(true));

		http.setJWT();

		const [success, error]: any = await Promisable.asPromise(
			http.post(`${url}`, data)
		);

		if (success) {
			navigate?.("/extras");
		dispatch?.(productActions.setLoading(false));

		}

		dispatch?.(productActions.setLoading(false));
		return [success, error];
	},
    getProduct: async () => {
		const dispatch = getAppDispatch();
		dispatch?.(productActions.setLoading(true));

		http.setJWT();

		const [success, error]: any = await Promisable.asPromise(
			http.get(`${url}`)
		);

		if (success) {
            const {products} = success.data.data
		dispatch?.(productActions.setLoading(false));
		dispatch?.(productActions.setProducts(products));

		}

		dispatch?.(productActions.setLoading(false));
		return [success, error];
	},
    getSingleProduct: async (id:any) => {
		const dispatch = getAppDispatch();
		dispatch?.(productActions.setLoading(true));

		http.setJWT();

		const [success, error]: any = await Promisable.asPromise(
			http.get(`${url}/getproduct/${id}`)
		);

		if (success) {
            const {product} = success.data.data
            console.log("prodeee", product)
		dispatch?.(productActions.setLoading(false));
		dispatch?.(productActions.setSingleProduct(product));

		}

		dispatch?.(productActions.setLoading(false));
		return [success, error];
	},
    updateProduct: async (
		id: string,
		data: any,
		navigate?: NavigateFunction
	) => {
		const dispatch = getAppDispatch();
		dispatch?.(productActions.setLoading(true));

		http.setJWT();

		const [success, error]: any = await Promisable.asPromise(
			http.patch(`${url}/update/${id}`, data)
		);

		if (success) {
			navigate?.("/extras");
		dispatch?.(productActions.setLoading(false));

		}

		dispatch?.(productActions.setLoading(false));
		return [success, error];
	},
    deleteProduct: async (
		id: string,
	) => {
		const dispatch = getAppDispatch();
		dispatch?.(modalActions.setLoading(true));

		http.setJWT();

		const [success, error]: any = await Promisable.asPromise(
			http.delete(`${url}/deleteproduct/${id}`)
		);

		if (success) {
            dispatch?.(modalActions.setLoading(false));
			dispatch?.(productActions.deleteProduct(id));

            dispatch?.(modalActions.closeModal());

		}

        dispatch?.(modalActions.setLoading(false));

		return [success, error];
	},
  
};

export default productService;
