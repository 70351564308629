import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AvailabilityState } from ".";

const initialState: AvailabilityState = {
  count: 0,
  refresh: 0,
  availabilities: [],
  availabilitiesByZone: {},
  availability: null,
  loading: true,
};

export const availabilitySlice = createSlice({
  name: "availability",
  initialState,
  reducers: {
    setClear: (state) => {
      state.availability = null
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    addAvailability: (state, action) => {
      state.availabilities = action.payload;
    },
    updateAvailability: (state, action) => {
      const { id, availability } = action.payload;
      state.availabilities.every(({ _id }, i) => {
        if (id === _id) {
          state.availabilities[i] = availability;
          return false;
        }
        return true;
      });
      state.availability = null;
    },
    setAvailability: (state, action) => {
      state.availability = action.payload;
    },
    setAvailabilities: (state, action) => {
      // let availabilities = action.payload;
      // let classesByZone: any = {};
      // availabilities.forEach((classs: any) => {
      //   const { zone_id,name } = classs;
      //   let zone_name = zone_id?._id ;

      //   if (classesByZone[zone_name])
      //     classesByZone[zone_name].classes.push(classs);
      //   else
      //     classesByZone[zone_name] = {
      //       models_length: 0,
      //       classes: [classs],
      //       zone_name: zone_id?.name ,
      //     };
      // });
      // state.availabilitiesByZone = classesByZone;
      state.availabilities = action.payload;

    },

    setDeleteAvailabilities: (state, action) => {
      state.availabilities = action.payload;
    },
  },
});

const availabilityReducer = availabilitySlice.reducer;

export const availabilityActions = availabilitySlice.actions;
export default availabilityReducer;
