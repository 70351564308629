import { PricingState } from ".";
import ToasterService from "utils/toaster.util";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { config } from "config";
import { SelectOption } from "components/atoms/Select";

const default_page_size = config.PAGE_SIZE;
const initialState: PricingState = {
	pricings: [],
	pricing: null,
	loading: true,
	pricingsByZone: {},
	current_filters: {},
	zoneOptions: [],
	priceClassesByZone: {},
	priceClassesOptions: [],
	refresh: 0,
	refreshLoader: false,
	filters: { page: 1, page_size: default_page_size },

};

export const pricingSlice = createSlice({
	name: "pricing",
	initialState,
	reducers: {
		setLoading: (state, action: PayloadAction<boolean>) => {
			state.loading = action.payload;
		},
		addPricing: (state, action) => {
			let pricingsByZone: any = {};
			let pricings = state.pricings;
			pricings.unshift(action.payload);
			pricings.forEach((pricing: any) => {
				const { city_id } = pricing;
				let zone_name = city_id?._id || "Undefined";
				if (pricingsByZone[zone_name])
					pricingsByZone[zone_name].pricings.push(pricing);
				else
					pricingsByZone[zone_name] = {
						pricings: [pricing],
						zone_name: city_id?.name,
					};
			});
			state.pricings = pricings;
			state.pricingsByZone = pricingsByZone;
		},
		updatePricing: (state, action) => {
			const { id, pricing } = action.payload;
			let pricingsByZone = state.pricingsByZone;
			state.pricings.every(({ _id }, i) => {
				if (id === _id) {
					state.pricings[i] = pricing;
					return false;
				}
				return true;
			});
			for (const city in pricingsByZone) {
				if (Object.prototype.hasOwnProperty.call(pricingsByZone, city)) {
					let done = false;
					let element = pricingsByZone[city];
					element.pricings.every(({ _id }: any, i: number) => {
						if (id === _id) {
							done = true;
							element.pricings[i] = pricing;
							return false;
						}
						return true;
					});
					if (done) break;
				}
			}
		},
		setPricing: (state, action) => {
			state.pricing = action.payload;
			state.refreshLoader = false;
		},
		setPricingByClass: (state, action) => {
			let class_id = action.payload;
			for (const pricing of state.pricings) {
				if (pricing.class_id._id === class_id) {
					state.pricing = pricing;
					break;
				}
			}

			if (!state.pricing)
				ToasterService.showError("Selected Class has no pricing yet!");
		},
		setPricings: (state, action) => {
			let pricingsByZone: any = {};
			const pricings = action.payload;
			pricings.forEach((pricing: any) => {
				const { zone_id, city_id } = pricing;
				let zone_name = zone_id?._id || city_id._id;
				// let zone_name = zone_id?._id || "Undefined";
				if (pricingsByZone[zone_name])
					pricingsByZone[zone_name].pricings.push(pricing);
				else
					pricingsByZone[zone_name] = {
						pricings: [pricing],
						zone_name: zone_id?.name ? zone_id?.name : city_id?.name,
					};
			});
			state.pricings = pricings;
			state.refreshLoader = false;
			state.pricingsByZone = pricingsByZone;
		},
		setPricingsLocal: (state, action) => {
			const { payload } = action
			state.pricingsByZone = payload;

		},
		setPriceZones: (state, action) => {
			const zones = action.payload;
			let options: SelectOption[] = [];
			zones.forEach(({ _id, name }: any) => options.push({ value: _id, label: name }));
			state.zoneOptions = options;
		},
		setPriceClasses: (state, action) => {
			let classesByZone: any = {};
			const classes = action.payload;
			classes.forEach((classs: any) => {
				const { zone_id } = classs;
				let zone_name = zone_id?._id;

				if (classesByZone[zone_name])
					classesByZone[zone_name].classes.push(classs);
				else
					classesByZone[zone_name] = {
						models_length: 0,
						classes: [classs],
						zone_name: zone_id?.name,
					};
			});

			state.priceClassesByZone = classesByZone;
		},

		setPriceClassesOptionsByZoneId: (state, action) => {
			const zone_id = action.payload;
			const classes = state.priceClassesByZone?.[zone_id]?.["classes"] || [];
			state.priceClassesOptions = classes.map(({ _id, name }: any) => ({
				value: _id,
				label: name,
			}));
		},
		clearPricingAssign: (state) => {
			state.zoneOptions = [];
			state.priceClassesOptions = [];
		},
		setPage: (state, action: PayloadAction<number>) => {
			state.refresh += 1;
			state.refreshLoader = true;
			state.filters.page = action.payload;
		},
		refresh: (state) => {
			state.refresh += 1;
			state.refreshLoader = true;
		},
		resetPage: (state) => {
			state.filters.page = 1;
		},
		setFilters: (state, action) => {
			state.filters = action.payload;
			state.refreshLoader = true;
		},
		setCurrentFilters: (state, action) => {
			state.current_filters = action.payload;
		},
		resetFilters: (state) => {
			state.refresh += 1;
			state.refreshLoader = true;
			state.filters = initialState.filters;
			state.current_filters = initialState.current_filters;
		},
	},
});

const pricingReducer = pricingSlice.reducer;

export const pricingActions = pricingSlice.actions;
export default pricingReducer;
