import { SelectOption } from "components/atoms/Select";
export { default } from "./Header";

export interface ILink {
  to: string;
  text: string;
  active: string;
  options?: SelectOption[];
}
export interface DropDown {
  to: string;
  text: string;
}

export const links: ILink[] = [
  { to: "/dashboard", text: "Dashboard", active: "dashboard" },
  { to: "/calendar?tab=0", text: "Calendar", active: "booking" },

  { to: "/bookings", text: "Bookings", active: "booking" },
  { to: "/users", text: "Users", active: "user" },
  { to: "/chauffeurs", text: "Chauffeurs", active: "chauffeur" },
  { to: "/vehicles", text: "Vehicles", active: "vehicle" },
  { to: "/models", text: "Models", active: "model" },
  { to: "/partners", text: "Partners", active: "partner" },
  { to: "/companies", text: "Companies", active: "compan" },
  { to: "/class", text: "Class", active: "class" },
  { to: "/Zone", text: "Zone", active: "Zone" },
  { to: "/campaigns", text: "Campaigns", active: "campaign" },
  { to: "/careers", text: "Careers", active: "careers" },
  { to: "/availability", text: "Availability", active: "availability" },
  // { to: "/extras", text: "Extras", active: "extras" },  // commented cause , no need to show now
];
export const invoiceDropDownList: DropDown[] = [
  { text: "Partner Invoices", to: "/partner-invoices" },
  { text: "Company Invoices", to: "/company-invoices" },
  { text: "Customer Invoices", to: "/customer-invoices" },
];
export const supportDropDownList: DropDown[] = [
  { text: "Support tickets", to: "/contacts" },
  { text: "Reported Problems", to: "/reported-problems" },
  { text: "Company Requests", to: "/company-requests" },
  { text: "Partner Priority", to: "/priority" },
  { text: "Partner Requests", to: "/partner-requests" },
  { text: "Events Requests", to: "/events" },
];
export const userDropDownList: DropDown[] = [
  { text: "Lohns", to: "/lohns" },
  { text: "Roles", to: "/roles" },
  { text: "Dynamics", to: "/dynamics" },
  { text: "Quotations", to: "/quotations" },
  { text: "Notifications", to: "/Notifications" },
  { text: "Commission", to: "/commission" },
  { text: "Booking Ratings", to: "/ratings" },
  { text: "Search History", to: "/search-history" },
];
export const priceDropDownList: DropDown[] = [
  { to: "/pricing", text: "Pricing" },
  { to: "/special-price", text: "Special Pricing" },
  { to: "/night-price", text: "Night Pricing" },
];
