import { Container } from "@mui/material";
import Banner from "components/templates/Banner";
import PriorityList from "./PriorityList";

export default function Priority() {
  return (
    <div>
      <Container maxWidth="lg">
        <Banner heading="Partner Priority"></Banner>
        <PriorityList />
      </Container>
    </div>
  );
}
