import { config } from "config";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
	BookingLoadingPayload,
	BookingState,
	NewBookingPayload,
	RemoveBookingPayload,
	SetBookingsPayload,
	SetFiltersPayload,
	SetPagePayload,
	UpdateBookingPayload,
	
} from ".";

const default_page_size = config.PAGE_SIZE;

const initialState: BookingState = {
	tab: 0,
	count: 0,
	refresh: 0,
	bookings: [],
	booking: null,
	loading: true,
	bookingRatings: [],
	refreshLoader: false,
	current_filters: {},
	filters: { page: 1, page_size: default_page_size },

};

export const invoiceBookingSlice = createSlice({
	name: "invoiceBooking",
	initialState,
	reducers: {
		refresh: (state) => {
			state.refresh += 1;
			state.refreshLoader = true;
		},
		setTab: (state, action: PayloadAction<number>) => {
			state.tab = action.payload;
		},
		setPage: (state, action: PayloadAction<SetPagePayload>) => {
			const { page } = action.payload;
			state.filters.page = page;
			state.refresh += 1;
			state.refreshLoader = true;
		},
		setPageRating: (state, action: PayloadAction<number>) => {
			state.filters.page =  action.payload;;
			state.refresh += 1;
			state.refreshLoader = true;
		},
		resetPage: (state) => {
			state.filters.page = 1;
		},
		setFilters: (state, action: PayloadAction<SetFiltersPayload>) => {
			const {  data } = action.payload;
			state.filters = data;
			state.refreshLoader = true;
		},
		resetFilters: (state, ) => {
			state.refresh += 1;
			state.refreshLoader = true;
			state.filters = { page: 1, page_size: default_page_size };
			state.current_filters = {};
		},
		setCurrentFilters: (state,action) => {
			const { data } = action.payload;
			state.current_filters = data;
		},
		setLoader: (state, action: PayloadAction<boolean>) => {
			state.loading = action.payload;
		},
		setLoading: (state, action: PayloadAction<BookingLoadingPayload>) => {
			const { loading } = action.payload;
			state.loading = loading;
			state.refreshLoader=loading
		},
		newBooking: (state, action: PayloadAction<NewBookingPayload>) => {
			const { booking } = action.payload;
			state.count += 1;
			state.bookings.unshift(booking);
		},
		updateBooking: (
			state,
			action: PayloadAction<UpdateBookingPayload>
		) => {
			const { id, booking } = action.payload;
			state.bookings.every(({ _id }, i) => {
				if (id === _id) {
					state.bookings[i] = booking;
					return false;
				}
				return true;
			});
		},
		removeBooking: (
			state,
			action: PayloadAction<RemoveBookingPayload>
		) => {
			const { id } = action.payload;
			state.bookings.every(({ _id }, i) => {
				if (id === _id) {
					state.count -= 1;
					state.bookings.splice(i, 1);
					return false;
				}
				return true;
			});
		},
		setBooking: (state, action) => {
			state.booking = action.payload;
		},
		updateBookingDetails: (state, action) => {
			const booking = action.payload;
			if (booking?._id === state.booking?._id) state.booking = booking;
		},
		setBookings: (state, action: PayloadAction<SetBookingsPayload>) => {
			const { count, bookings } = action.payload;

			state.refreshLoader = false;

			state.count = count;
			state.bookings = bookings;
		},
		setBookingsRatings: (state, action) => {
			const {trips,count} = action.payload || [];
			state.bookingRatings = trips?.filter?.(
				(bookingRating: any) => bookingRating?.ratings
			);
			state.refreshLoader = false;			
			state.count = count;
		},
		sendMarket: (state, action: PayloadAction<UpdateBookingPayload>) => {
			const { id, booking } = action.payload;

			state.bookings.every(({ _id }, i) => {
				if (id === _id) {
					state.count -= 1;
					state.count += 1;
					state.bookings.splice(i, 1);
					state.bookings.unshift(booking);
					return false;
				}
				return true;
			});
		},
		acceptMarket: (state, action: PayloadAction<UpdateBookingPayload>) => {
			const { id, booking } = action.payload;
			state.bookings.every(({ _id }, i) => {
				if (id === _id) {
					state.count -= 1;
					state.count += 1;
					state.bookings.splice(i, 1);
					state.bookings.unshift(booking);
					return false;
				}
				return true;
			});
		},
	},
});

const invoiceBookingReducer = invoiceBookingSlice.reducer;

export const invoiceBookingActions = invoiceBookingSlice.actions;
export default invoiceBookingReducer;
