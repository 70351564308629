import { CompanyState } from ".";
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { SelectOption } from "components/atoms/Select";
;

const initialState: CompanyState = {
	companies: [],
	company: null,
	loading: true,
	company_requests: [],
	companyOptions:[],
};

export const companySlice = createSlice({
	name: "company",
	initialState,
	reducers: {
		setLoading: (state, action: PayloadAction<boolean>) => {
			state.loading = action.payload;
		},
		addCompany: (state, action) => {
			state.companies.unshift(action.payload);
		},
		updateCompany: (state, action) => {
			const { id, company } = action.payload;
			state.companies.every(({ _id }, i) => {
				if (id === _id) {
					state.companies[i] = company;
					return false;
				}
				return true;
			});
		},
		setCompany: (state, action) => {
			state.company = action.payload;
		},
		setCompanies: (state, action) => {
			const companies= action.payload;
			state.companies = action.payload;
			let options: SelectOption[] = [];
			companies.forEach(({ _id, company_name }: any) =>
				options.push({ value: _id, label: company_name })
			);
			state.companyOptions = options;
		},
		setCompanyRequests: (state, action) => {
			state.company_requests = action.payload;
		},
		deleteCompanyRequestById: (state, action) => {
			const id = action.payload;
			state.company_requests = state.company_requests.filter(
				({ _id }) => id !== _id
			);
		},
	},
});

const companyReducer = companySlice.reducer;

export const companyActions = companySlice.actions;
export default companyReducer;
