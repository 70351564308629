import { config } from "config";
import {
  DeleteScriptPayload,
  IScripts,
  SetFiltersPayload,
  SetScriptsPayload,
  UpdateScriptPayload,
  SetScriptBookingsPayload,
} from ".";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SelectOption } from "components/atoms/Select";

const default_page_size = config.PAGE_SIZE;

const initialState: IScripts = {
  count: 0,
  refresh: 0,
  scripts:[],
  script: null,
  scriptLogs: [],
  scriptOptions: [],
  bookings: null,
  loading: false,
  logsLoading: false,
  refreshLoader: false,
  current_filters: {},
  page:1,
  filters: { page: 1, page_size: default_page_size },
};


export const scriptSlice = createSlice({
  name: "script",
  initialState,
  reducers: {
    setLogs: (state, action: PayloadAction<any>) => {
      let logs = state.scriptLogs;
      logs.push(...action.payload);
      state.scriptLogs = logs;
    },
  
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setLogsLoading: (state, action: PayloadAction<boolean>) => {
      state.logsLoading = action.payload;
    },
    clear_search: (state) => {
      state.scripts = [];
      state.scriptOptions = [];
    },
    updateScript: (state, action: PayloadAction<UpdateScriptPayload>) => {
      const { id, script } = action.payload;
      state.scripts.every(({ _id }, i) => {
        if (id === _id) {
          state.scripts[i] = script;
          return false;
        }
        return true;
      });
    },
    deleteScriptById: (state, action: PayloadAction<DeleteScriptPayload>) => {
      const { id } = action.payload;
      state.scripts.every(({ _id }, i) => {
        if (id === _id) {
          state.count -= 1;
          state.scripts.splice(i, 1);
          return false;
        }
        return true;
      });
    },
    setScript: (state, action) => {
      state.script = action.payload;
    },
    setScripts: (state, action: PayloadAction<SetScriptsPayload>) => {
      const { count, scripts } = action.payload;
      state.count = count;
      state.scripts = scripts;
      state.refreshLoader = false;
    },
    setScriptBookings: (state, action: PayloadAction<SetScriptBookingsPayload>) => {
      state.bookings =  action.payload;
    },
    setSearchScripts: (state, action: PayloadAction<SetScriptsPayload>) => {
      const { count, scripts } = action.payload;
      state.count = count;
      state.refreshLoader = false;

      let options: SelectOption[] = [];
      scripts.forEach(({ _id, first_name, last_name, phone }: any) =>
        options.push({
          value: _id,
          label: first_name + " " + last_name + " - " + phone,
        })
      );
      state.scripts = scripts;
      state.scriptOptions = options;
    },
    setPage: (state) => {
      state.page += 1;
    },
    refresh: (state) => {
      state.refresh += 1;
      state.refreshLoader = true;
    },
    resetPage: (state, action: PayloadAction<any>) => {
      state.page = 1;
    },
    setFilters: (state, action: PayloadAction<SetFiltersPayload>) => {
      const { data } = action.payload;
      state.filters = data;
      state.refreshLoader = true;
    },
    setCurrentFilters: (state, action: PayloadAction<SetFiltersPayload>) => {
      const { data } = action.payload;

      state.current_filters = data;
    },
    resetFilters: (state, action: PayloadAction<any>) => {
      const type = action.payload;
      state.refresh += 1;
      state.refreshLoader = true;
      state.filters = initialState.filters;
      state.current_filters = initialState.current_filters;
    },
  },
});

const scriptReducer = scriptSlice.reducer;

export const scriptActions = scriptSlice.actions;
export default scriptReducer;
