import { config } from "config";
import { dynamicState } from ".";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";


const default_page_size = config.PAGE_SIZE;

const initialState: dynamicState = {
  dynamics: [],
  loading: true,
  refresh: 0,
  refreshLoader: false,
  current_filters: {},
  filters: { page: 1, page_size: default_page_size },
};

export const dynamicSlice = createSlice({
  name: "dynamic",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setdynamics: (state, action) => {
      state.dynamics = action.payload;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.refresh += 1;
      state.refreshLoader = true;
      state.filters.page = action.payload;
    },
    refresh: (state) => {
      state.refresh += 1;
      state.refreshLoader = true;
    },
    resetPage: (state) => {
      state.filters.page = 1;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
      state.refreshLoader = true;
    },
    setCurrentFilters: (state, action) => {
      state.current_filters = action.payload;
    },
    resetFilters: (state) => {
      state.refresh += 1;
      state.refreshLoader = true;
      state.filters = initialState.filters;
      state.current_filters = initialState.current_filters;
    },
  },
});

export const dynamicReducer = dynamicSlice.reducer;

export const dynamicActions = dynamicSlice.actions;
