import { greaterThan0, number, positive, required } from "utils/validate.util";
import InputRedux from "components/molecules/InputRedux";

import { ReduxFormField } from "components/molecules/ReduxFormFields";

export { default } from "./UpdatePriorityForm";

export const fields: ReduxFormField[] = [
  {
    name: "title",
    label: "Title",
    validate: [required],
    component: InputRedux,
    cellProps: { md: 6 },
    InputProps: { size: "small" },
  },
  {
    name: "level",
    label: "Level",
    validate: [required, number, positive, greaterThan0],
    component: InputRedux,
    cellProps: { md: 6 },
    InputProps: { size: "small", disabled: true },
  },
  {
    name: "expiryInMinutes",
    label: "Expiry (Minutes)",
    validate: [required, number, positive, greaterThan0],
    component: InputRedux,
    cellProps: { md: 6 },
    InputProps: { size: "small" },
  },
];
