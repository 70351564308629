import http from "./http.service";
import Promisable from "./promisable.service";
import { getAppDispatch } from "utils/dispatch.util";
import { driverActions } from "redux/slices/smartDriver";
import { modalActions } from "redux/slices/modal";
import SmartService from "./smart.service";

const url = "/smartDriver";

const DriverService = {
  getDrivers: async (zone: string) => {
    const dispatch = getAppDispatch();
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}/list?zone=${zone}`)
    );

    if (success) {
      const { drivers } = success.data.data;
      dispatch?.(driverActions.setDrivers(drivers));
    }

    dispatch?.(modalActions.setLoading(false));
    return [success, error];
  },

  addDriver: async (data: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(driverActions.setLoading(true));
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/create`, data)
    );
    dispatch?.(modalActions.setLoading(false));
    dispatch?.(modalActions.closeModal());

    if (success) {
      const { driver } = success.data.data;
      dispatch?.(driverActions.setLoading(false));

      dispatch?.(driverActions.setDrivers(driver));
    }

    dispatch?.(driverActions.setLoading(false));
    return [success, error];
  },
  updateDriver: async (data: any, zone: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/update`, data)
    );

    if (success) {
      dispatch?.(driverActions.setLoading(false));
      dispatch?.(modalActions.closeModal());

      SmartService.getTimeLine(zone, false);

      // dispatch?.(driverActions.setDrivers(driver));
    }

    dispatch?.(modalActions.setLoading(false));
    return [success, error];
  },
};

export default DriverService;
