import format from "date-fns/format";
import React, { useEffect } from "react";
import { change } from "redux-form";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import PayRollService from "services/payroll.service";
import UpdatePayRollForm from "./UpdatePayRollForm";

const UpdatePayRoll = () => {
  let form = "UpdatePayRollForm";
  const dispatch = useAppDispatch();
  const _id = useAppSelector((state) => state.modal?.data?.id);
  const checkIn = useAppSelector((state) => state.modal?.data?.checkIn);
  const checkOut = useAppSelector((state) => state.modal?.data?.checkOut);

  const handleSubmit = async (values: any) => {
    let data = { ...values, _id };

    const { checkIn, checkOut } = data;
    if (checkIn)
      data.checkIn = format(
        new Date(checkIn.date),
        "yyyy-MM-dd HH:mm:ss.SSSSSS"
      );
    if (checkOut)
      data.checkOut = format(
        new Date(checkOut.date),
        "yyyy-MM-dd HH:mm:ss.SSSSSS"
      );

    // console.log({
    //   checkIn: moment(data.checkIn),
    // });

    // return;
    PayRollService.updatePayRoll(data, dispatch);
  };

  useEffect(() => {
    checkIn &&
      dispatch(
        change(form, "checkIn", {
          date: checkIn.replace("Z", ""),
          error: false,
        })
      );
    checkOut &&
      dispatch(
        change(form, "checkOut", {
          date: checkOut.replace("Z", ""),
          error: false,
        })
      );
  }, [checkIn, checkOut, dispatch, form]);

  return (
    <>
      <UpdatePayRollForm onSubmit={handleSubmit} />
    </>
  );
};

export default UpdatePayRoll;
