import { Card, CardContent } from "@mui/material";
import { Task } from ".";

type TaskItemProps = {
  task: Task;
};

const TaskItem = ({ task }: TaskItemProps) => {
  return (
    <Card>
      <CardContent sx={{ fontSize: "14px" }}>{task.title}</CardContent>
    </Card>
  );
};

export default TaskItem;
