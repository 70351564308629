import { ILohns } from ".";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: ILohns = {
  refresh: 0,
  loading: false,
  refreshLoader: false,
  lohns: [],
  current_filters: {},
  filters: {},
};

export const lohnSlice = createSlice({
  name: "lohn",
  initialState,
  reducers: {
    setLoader: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setLohns: (state, action: PayloadAction<any[]>) => {
      state.refreshLoader = false;
      state.lohns = action.payload;
    },
    updateLohn: (state, action: PayloadAction<any>) => {
      const lohn = action.payload;
      if (!lohn?._id) return;
      const index = state.lohns.findIndex(({ _id }) => _id === lohn._id);
      if (index !== -1) {
          state.lohns[index] = lohn;
      } else {
          state.lohns.push(lohn);
      }
    },
    deleteLohn: (state, action: PayloadAction<any>) => {
      const id = action.payload;

      if (!id) return;
      state.lohns.every(({ _id }, i) => {
        if (id === _id) {
          state.lohns.splice(i, 1);
          return false;
        }
        return true;
      });
    },
    filterLohns: (state, action: PayloadAction<any>) => {
      const { type, lohn } = action.payload;

      state.lohns = state.lohns.filter(({ _id }) => _id !== lohn._id);
    },
    setFilters: (state, action: PayloadAction<any>) => {
      const { type, data } = action.payload;
      state.filters = data;
      state.refreshLoader = true;
    },
    resetFilters: (state) => {
      state.refresh += 1;
      state.refreshLoader = true;
      state.filters = initialState.filters;
      state.current_filters = initialState.current_filters;
    },
    setCurrentFilters: (state, action: PayloadAction<any>) => {
      const { type, data } = action.payload;
      state.current_filters = data;
    },
  },
});

const lohnReducer = lohnSlice.reducer;

export const lohnActions = lohnSlice.actions;
export default lohnReducer;
