import { IDriver } from ".";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: IDriver = {
  refresh: 0,
  loading: false,
  refreshLoader: false,
  drivers: [],
};

export const driverSlice = createSlice({
  name: "smartDriver",
  initialState,
  reducers: {
    setLoader: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setDrivers: (state, action: PayloadAction<any[]>) => {
      console.log("action.payload", action.payload);
      state.refreshLoader = false;
      state.drivers = action.payload;
    },
    updateDriver: (state, action: PayloadAction<any>) => {
      const driver = action.payload;
      if (!driver?._id) return;
      const index = state.drivers.findIndex(({ _id }) => _id === driver._id);
      if (index !== -1) {
        state.drivers[index] = driver;
      } else {
        state.drivers.push(driver);
      }
    },
  },
});

const driverReducer = driverSlice.reducer;

export const driverActions = driverSlice.actions;
export default driverReducer;
