import NightPricesList from "./NightPricesList";
import { Button, Container, Grid } from "@mui/material";
import { useNavigate } from "react-router";
import { useAppDispatch } from "redux/hooks";
import Banner from "components/templates/Banner";
import BlockComponent from "components/organisms/BlockComponent/BlockComponent";

export default function Availability() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  return (
    <div>
      <Container maxWidth="lg">
        <Banner heading="Night Price">
        <BlockComponent permission={"price:write"}>
          <Button variant="contained" onClick={() => navigate("/add-night-price")}>
            Add price
          </Button>
          </BlockComponent>
        </Banner>
        <NightPricesList />
      </Container>
    </div>
  );
}
