export { default, modalActions, modalSlice } from "./modalSlice";

export enum MODAL {
  ADD_NOTES = "ADD_NOTES",
  PAY_INVOICE = "PAY_INVOICE",
  DELETE_USER = "DELETE_USER",
  ADD_PAYMENT = "ADD_PAYMENT",
  HANDLE_STATUS = "HANDLE_STATUS",
  CHILDREN_SEATS = "CHILDREN_SEATS",
  ASSIGN_BOOKING = "ASSIGN_BOOKING",
  ASSIGN_PRICE = "ASSIGN_PRICE",
  DISABLE_DRIVER = "DISABLE_DRIVER",
  CANCEL_BOOKING = "CANCEL_BOOKING",
  MARKET_TRANSFER = "MARKET_TRANSFER",
  UPLOAD_DOCUMENT = "UPLOAD_DOCUMENT",
  CONFIRMATION_FORM = "CONFIRMATION_FORM",
  CONFIRMATION_MODAL = "CONFIRMATION_MODAL",
  EXPORT_TABLE_OPTIONS = "EXPORT_TABLE_OPTIONS",
  UPDATE_BOOKING_STATUS = "UPDATE_BOOKING_STATUS",
  UPDATE_BOOKING_BILLING_ADDRESS = "UPDATE_BOOKING_BILLING_ADDRESS",
  UPDATE_PAYROLL = "UPDATE_PAYROLL",
  SEARCH_HISTORY_STATS = "SEARCH_HISTORY_STATS",
  SET_SMART_SCRIPT = "SET_SMART_SCRIPT",
  BOOKING_DETAILS = "BOOKING_DETAILS",
  ASSIGN_PARTNER_VEHICLE = "ASSIGN_PARTNER_VEHICLE",
  ASSIGN_FAV_DRIVER = "ASSIGN_FAV_DRIVER",
  Add_SHIFT = "Add_SHIFT",
  UPDATE_DYNAMICS = "UPDATE_DYNAMICS",
  SMART_DRIVER = "SMART_DRIVER",
  UPDATE_PAYMENT_STATUS="UPDATE_PAYMENT_STATUS",
}

export type ModalType = "" | keyof typeof MODAL;

export interface OpenModalState {
  data: any;
  width: any;
  type: ModalType;
  loading?: boolean;
  closeBackdropClick?: boolean;
}

export type ModalState = OpenModalState & {
  open: boolean;
};
