
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { productState } from ".";


const initialState: productState = {
    products: [],
    singleProduct: [],
  loading: false,
};



export const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    setProducts: (state, action) => {
        state.products = action.payload
      
    },
    setSingleProduct: (state, action) => {
        state.singleProduct = action.payload
      
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    deleteProduct: (state, action: PayloadAction<string>) => {
        const id = action.payload;
        state.products = state.products.filter(({ _id }) => _id !== id);
    },
  },
});

const productReducer = productSlice.reducer;

export const productActions = productSlice.actions;
export default productReducer;
