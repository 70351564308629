import "./Header.css";
import {
  invoiceDropDownList,
  links,
  priceDropDownList,
  supportDropDownList,
  userDropDownList,
} from ".";
import { Fragment, useEffect, useState } from "react";
import { Grid, Stack } from "@mui/material";
import logo from "assets/logo-light.png";
import { useAppSelector } from "redux/hooks";
import AuthService from "services/auth.service";
import useEffectOnce from "hooks/useEffectOnce";
import BasicMenu from "components/atoms/BasicMenu";
import SocketService from "services/socket.service";
import OneSignalService from "services/onesignal.service";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaAngleDown } from "react-icons/fa";

export default function Header() {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const user = useAppSelector((state) => state.auth.user);
  const routes = useAppSelector((state) => state.auth?.user?.role_id?.routes);
  const [headerList, setHeaderList] = useState<any[]>([]);
  const [userDropDown, setuserDropDown] = useState<any>([]);
  const [invoiceDropDown, setInvoiceDropDown] = useState<any>([]);
  const [supportDropDown, setSupportDropDown] = useState<any>([]);
  const [priceDropDown, setPriceDropDown] = useState<any>([]);

  useEffectOnce(() => {
    if (!user) return;
    OneSignalService.connect(user._id);
    SocketService.connect(user);
  });
  useEffect(() => {
    if (typeof routes === "object") {
      let private_user_routesCheck = userDropDownList.filter((item: any) =>
        routes.includes(item.to)
      );
      let private_headerList_routesCheck = links.filter((item: any) =>
        routes.includes(item.to)
      );
      let private_invoice_routesCheck = invoiceDropDownList.filter(
        (item: any) => routes.includes(item.to)
      );
      let private_support_routesCheck = supportDropDownList.filter(
        (item: any) => routes.includes(item.to)
      );
      let private_price_routesCheck = priceDropDownList.filter((item: any) =>
        routes.includes(item.to)
      );

      private_user_routesCheck.push({
        text: "Logout",
        to: "/logout",
      });
      for (let i = 0; i < private_headerList_routesCheck?.length; i++) {
        if (private_headerList_routesCheck[i].to === "/bookings") {
          private_headerList_routesCheck[i] = {
            to: "/bookings?tab=0",
            text: "Bookings",
            active: "bookings",
          };
          private_headerList_routesCheck.splice(i + 1, 0, {
            to: "/calendar?tab=0",
            text: "Calendar",
            active: "calendar",
          });

          break;
        }
      }
      setHeaderList(private_headerList_routesCheck);
      setuserDropDown(private_user_routesCheck);
      setSupportDropDown(private_support_routesCheck);
      setPriceDropDown(private_price_routesCheck);
      setInvoiceDropDown(private_invoice_routesCheck);
    }
  }, [routes]);
  if (!user || pathname === "/") return <></>;
  return (
    <header>
      <div className="navbar">
        <Grid container alignItems="center">
          <Grid item xs={12} lg={1}>
            <Link to="/bookings">
              <div className="logo">
                <img
                  src={logo}
                  alt="logo"
                  loading="lazy"
                  width={"180px"}
                  height={"100%"}
                />
              </div>
            </Link>
          </Grid>
          <Grid item xs={12} lg={11}>
            <ul>
              {headerList.length > 0
                ? headerList.map(({ to, text, active, options }, i) => (
                    <Fragment key={i}>
                      {options ? (
                        <li>
                          <BasicMenu
                            avatar={false}
                            color="#FFFFFF"
                            list={options.map(({ value, label }: any) => ({
                              text: label,
                              onClick: () => navigate(value),
                            }))}
                          >
                            {text}
                          </BasicMenu>
                        </li>
                      ) : (
                        <li key={i}>
                          <Link
                            to={to}
                            className={
                              pathname + search === active
                                ? "active"
                                : pathname.includes(active) &&
                                  !pathname.includes("invoice")
                                ? "active"
                                : ""
                            }
                          >
                            {text}
                          </Link>
                        </li>
                      )}
                    </Fragment>
                  ))
                : null}
              {invoiceDropDown.length > 0 ? (
                <li>
                  <BasicMenu
                    list={invoiceDropDown.map(({ text, to }: any) => ({
                      text: text,
                      onClick: () => navigate(to),
                    }))}
                    avatar={false}
                    color="#ffffff"
                  >
                    <Stack direction="row" alignItems="center" columnGap={1}>
                      <span>Invoices</span>
                      <FaAngleDown />
                    </Stack>
                  </BasicMenu>
                </li>
              ) : null}
              {priceDropDown.length > 0 ? (
                <li>
                  <BasicMenu
                    list={priceDropDown.map(({ text, to }: any) => ({
                      text: text,
                      onClick: () => navigate(to),
                    }))}
                    avatar={false}
                    color="#ffffff"
                  >
                    <Stack direction="row" alignItems="center" columnGap={1}>
                      <span>Prices</span>
                      <FaAngleDown />
                    </Stack>
                  </BasicMenu>
                </li>
              ) : null}

              {supportDropDown.length > 0 ? (
                <li>
                  <BasicMenu
                    list={supportDropDown.map(({ text, to }: any) => ({
                      text: text,
                      onClick: () => navigate(to),
                    }))}
                    avatar={false}
                    color="#ffffff"
                  >
                    <Stack direction="row" alignItems="center" columnGap={1}>
                      <span>Support</span>
                      <FaAngleDown />
                    </Stack>
                  </BasicMenu>
                </li>
              ) : null}
              <li>
                <div style={{ padding: "10px 0" }}>
                  <BasicMenu
                    avatar={true}
                    list={userDropDown.map(({ text, to }: any) => ({
                      text: text,
                      onClick: () =>
                        to == "/logout" ? AuthService.logout() : navigate(to),
                    }))}
                  >
                    {user.first_name.charAt() + user.last_name.charAt()}
                  </BasicMenu>
                </div>
              </li>
            </ul>
          </Grid>
        </Grid>
      </div>
    </header>
  );
}
