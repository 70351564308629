import { useEffect } from "react";
import { change } from "redux-form";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import productService from "services/product.service";

export default function UpdateProductForm({ id }: any) {
	const form = "AddProductForm";
const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const product = useAppSelector((state)=>state.product.singleProduct[0])
	

	useEffect(() => {
		productService.getSingleProduct(id);
		
	}, [id, dispatch]);

	useEffect(() => {
		if (!product || product?.data === "Not Found") return;
		const {visibility,title,product_rank,brutto,netto,vat,description,buying_limit,isActive,image}= product

		dispatch(change(form, "isActive", isActive));
		dispatch(change(form, "title", title));
		dispatch(change(form, "brutto", brutto));
		dispatch(change(form, "vat", vat));
		dispatch(change(form, "netto", netto));
		dispatch(change(form, "buying_limit", buying_limit));
		dispatch(change(form, "visibility", visibility));
		dispatch(change(form, "image", {image}));

		
		dispatch(change(form, "product_rank", product_rank));
		dispatch(change(form, "description", description));
		
	}, [product,navigate, dispatch]);

	return null;
}
