import {
  ReportedProblemLoadingPayload,
  ReportedProblemState,
  SetReportedProblemPayload,
  SetReportedProblemsPayload,
} from ".";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialReportedProblem = {
  reportedProblems: [],
  loading: true,
};

const initialState: ReportedProblemState = {
  tab: 0,
  reportedProblem: null,
  loading: true,
  open: initialReportedProblem,
  close: initialReportedProblem,
};

export const reportedProblemSlice = createSlice({
  name: "reportedProblem",
  initialState,
  reducers: {
    setTab: (state, action: PayloadAction<number>) => {
      state.tab = action.payload;
    },
    setLoader: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setLoading: (
      state,
      action: PayloadAction<ReportedProblemLoadingPayload>
    ) => {
      const { type, loading } = action.payload;
      state[type].loading = loading;
    },
    setReportedProblems: (
      state,
      action: PayloadAction<SetReportedProblemsPayload>
    ) => {
      const { type, reportedProblems } = action.payload;

      state[type].reportedProblems = reportedProblems;
    },
    updateReportedProblems: (
      state,
      action: PayloadAction<SetReportedProblemPayload>
    ) => {
      const { type, reportedProblem } = action.payload;

      if (!reportedProblem?._id) return;

      state[type].reportedProblems = state[type].reportedProblems.filter(
        ({ _id }) => _id !== reportedProblem._id
      );
    },
  },
});

const reportedProblemReducer = reportedProblemSlice.reducer;

export const reportedProblemActions = reportedProblemSlice.actions;
export default reportedProblemReducer;
