import { file, number, required } from "utils/validate.util";
import InputRedux from "components/molecules/InputRedux";
import CheckboxRedux from "components/molecules/CheckboxRedux";
import { ReduxFormField } from "components/molecules/ReduxFormFields";
import FileUploadRedux from "components/molecules/FileUploadRedux";
import SelectRedux from "components/molecules/SelectRedux";

export { default } from "./AddProductForm";

export const fields: ReduxFormField[] = [
	{
		name: "isActive",
		label: "Active",
		component: CheckboxRedux,
		cellProps: { lg: 12 },
	},
	{
		name: "title",
		label: "Title",
		validate: [required],
		component: InputRedux,
		cellProps: {lg: 4 },
	},
	{
		name: "url",
		label: "Image",
		validate: [required, file],
		component: FileUploadRedux,
		cellProps: { md: 4 },
		FileUploadProps: {
			maxSize: 5,
			accept: [".jpg", ".jpeg", ".png"],
		},
	},
	{
		name: "brutto",
		label: "Brutto Price",
		validate: [required,number],
		component: InputRedux,
		cellProps: {lg: 4 },
	},
	{
		name: "vat",
		label: "Vat Price",
		validate: [required,number],
		component: InputRedux,
		cellProps: {lg: 4 },
		InputProps: {InputProps: { endAdornment: "%" } },

	},
	{
		name: "netto",
		label: "Netto Price",
		validate: [required,number],
		component: InputRedux,
		cellProps: {lg: 4 },
		InputProps: { disabled: true},


	},
	
	{
		name: "buying_limit",
		label: "Buying Limit",
		validate: [required,number],
		component: InputRedux,
		cellProps: {lg: 4 },
	},
	{
		name: "visibility",
		label: "Visibility Time",
		validate: [required],
		component: SelectRedux,
		SelectProps: {
			options: [
				{ label: "12 Hours", value: "12 Hours" },
				{ label: "24 Hours", value: "24 Hours" },
			],
		},
		cellProps: {lg: 4 },
	},
	{
		name: "product_rank",
		label: "Product Rank",
		validate: [required,number],
		component: InputRedux,
		cellProps: {lg: 4 },

	},
	{
		name: "description",
		label: "Description",
		validate: [required],
		component: InputRedux,
		cellProps: {lg: 12 },
		InputProps: { multiline: true, rows: 4 },


	},

];
