import http from "./http.service";
import Promisable from "./promisable.service";
import { scriptActions } from "redux/slices/script";
import { getAppDispatch } from "utils/dispatch.util";
import { modalActions } from "redux/slices/modal/modalSlice";

const url = "/script";

const ScriptService = {
	getOne: async (id: string) => {
		const dispatch = getAppDispatch();
		dispatch?.(scriptActions.setLoading(true));

		http.setJWT();

		const [success, error]: any = await Promisable.asPromise(
			http.get(`${url}/${id}`)
		);

		if (success) {
			const { script } = success.data.data;
			dispatch?.(scriptActions.setScript(script));
			dispatch?.(scriptActions.setLoading(false));
		} else dispatch?.(scriptActions.setScript({ data: "Not Found" }));

		dispatch?.(scriptActions.setLoading(false));
		return [success, error];
	},

	getScripts: async (data: any) => {
		const dispatch = getAppDispatch();

		if (data?.all) dispatch?.(modalActions.setLoading(true));
		else dispatch?.(scriptActions.setLoading(true));

		http.setJWT();

		const [success, error]: any = await Promisable.asPromise(
			http.post(`${url}`, data)
		);

		if (success) {
			const { scripts, count } = success.data.data;
			dispatch?.(scriptActions.setScripts({ scripts, count }));
		}

		if (data?.all) dispatch?.(modalActions.setLoading(false));
		else dispatch?.(scriptActions.setLoading(false));
		return [success, error];
	},
	getScriptBookings: async (id: any, zone: any) => {
		const dispatch = getAppDispatch();

		dispatch?.(modalActions.setLoading(true));

		http.setJWT();

		const [success, error]: any = await Promisable.asPromise(
			http.get(`${url}/bookings/${id}/${zone}`)
		);

		if (success) {
			const { bookings, count } = success.data.data;
			dispatch?.(scriptActions.setScriptBookings({ bookings, count }));

		}

		dispatch?.(modalActions.setLoading(false));
		return [success, error];
	},
	getScriptLogs: async (source: any, zone: any) => {
		http.setJWT();
		const [success, error]: any = await Promisable.asPromise(
			http.get(`${url}/logs/${source}/${zone}`)
		);
		if (success.data) {
			const { data } = success.data
			return data
		}
		return [];
	},
	updateScript: async (id: string, data: any, navigate?: any) => {
		const dispatch = getAppDispatch();
		http.setJWT();

		const [success, error]: any = await Promisable.asPromise(
			http.patch(`${url}/${id}`, data)
		);

		if (success) {
			if (navigate) navigate(-1)
			const { script } = success.data.data;
			dispatch?.(scriptActions.setScript(script));
		}

		return [success, error];
	},

	addScript: async (data: any, navigate?: any) => {
		const dispatch = getAppDispatch();
		http.setJWT();
		dispatch?.(scriptActions.setLoading(true));

		const [success, error]: any = await Promisable.asPromise(
			http.post(`${url}/add`, data)
		);

		if (success) {
			if (navigate) navigate(-1)

			const { script } = success.data.data;
			dispatch?.(scriptActions.setScript(script));
		}
		dispatch?.(scriptActions.setLoading(false));

		return [success, error];
	},
	deleteScript: async (id: string) => {
		const dispatch = getAppDispatch();
		dispatch?.(modalActions.setLoading(true));

		http.setJWT();

		const [success, error]: any = await Promisable.asPromise(
			http.delete(`${url}/${id}`)
		);

		if (success) {
			dispatch?.(scriptActions.deleteScriptById({ id }));
			dispatch?.(modalActions.closeModal());
		}

		dispatch?.(modalActions.setLoading(false));
		return [success, error];
	},

	updateBillingAddress: async (id: string, data: any) => {
		const dispatch = getAppDispatch();
		http.setJWT();

		const [success, error]: any = await Promisable.asPromise(
			http.post(`${url}/updatebillingaddress/${id}`, data)
		);

		if (success) {
			const { script } = success.data.data;
			dispatch?.(scriptActions.setScript(script));
		}

		return [success, error];
	},

	addAdminNotes: async (id: string, data: any) => {
		const dispatch = getAppDispatch();

		http.setJWT();

		const [success, error]: any = await Promisable.asPromise(
			http.post(`${url}/admin-notes/${id}`, data)
		);

		if (success) {
			const { script } = success.data.data;
			dispatch?.(scriptActions.setScript(script));
		}

		return [success, error];
	},

	stats: async (data: any) => {
		const dispatch = getAppDispatch();

		dispatch?.(scriptActions.setLoading(true));
		dispatch?.(modalActions.setLoading(true))

		http.setJWT();

		const [success, error]: any = await Promisable.asPromise(
			http.post(url + `/stats`, data)
		);
		if (success.data) {
			const { script } = success.data.data;
			dispatch?.(scriptActions.updateScript({ script, id: data._id }));
			dispatch?.(modalActions.closeModal());
			dispatch?.(modalActions.setLoading(false))


		}

		dispatch?.(scriptActions.setLoading(false));
	},
};

export default ScriptService;
