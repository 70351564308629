import CircleLoader from "components/atoms/CircleLoader";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { change } from "redux-form";
import { useAppSelector } from "redux/hooks";
import UpdatePriorityForm from "./UpdatePriorityForm";
import PriorityService from "services/priority.service";
import { useDispatch } from "react-redux";

export default function UpdatePriority() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const form = "UpdatePriorityForm";
  const priority = useAppSelector((state) => state.priority.priority);
  const loading = useAppSelector((state) => state.formLoader.loading);

  useEffect(() => {
    const { title, level, expiryInMinutes } = priority;

    dispatch(change(form, "title", title));
    dispatch(change(form, "level", level));
    dispatch(change(form, "expiryInMinutes", expiryInMinutes));
  }, [dispatch, priority]);

  const handleSubmit = async (values: any) => {
    let data = { ...values };
    PriorityService.updateById(`${id}`, data);
  };

  return (
    <div>
      <div style={{ position: "relative" }}>
        {loading && <CircleLoader />}
        <h3 style={{ marginTop: "0" }}>Update Priority</h3>
        <UpdatePriorityForm onSubmit={handleSubmit} />
      </div>
    </div>
  );
}
