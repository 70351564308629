import { useState } from "react";
import { DesktopDateTimePicker } from "@mui/lab";
import Input from "../Input";
import { DateTimePickerProps } from ".";

export default function DateTimePicker({
  onChange,
  editable,
  InputFieldProps,
  value: { date: value = "" },
  ...rest
}: DateTimePickerProps) {
  const [open, setOpen] = useState(false);
  const InputProps = {
    autoComplete: "off",
    InputLabelProps: { shrink: true },
    ...InputFieldProps,
    onClick: () => setOpen(true),
    onKeyDown: (e: any) => {
      if (!editable) e.preventDefault();
    },
    error: InputFieldProps?.helperText ? InputFieldProps?.error : false,
  };
  return (
    <DesktopDateTimePicker
      open={open}
      ampm={false}
      value={value}
      showTodayButton
      ampmInClock={false}
      inputFormat="dd.MM.yyyy HH.mm"
      onOpen={() => setOpen(true)}
      onClose={() => {
        const timeoutId = setTimeout(() => {
          setOpen(false);
        }, 100);

        return () => clearTimeout(timeoutId);
      }}
      onChange={(date) =>
        onChange?.({ date: date ? `${date}` : "", error: false })
      }
      onAccept={(date) =>
        onChange?.({ date: date ? `${date}` : "", error: false })
      }
      onError={(reason, date) => {
        if (!reason) return;
        onChange?.({ date: date ? `${date}` : "", error: true });
      }}
      {...rest}
      renderInput={(params) => <Input {...params} {...InputProps} />}
      PopperProps={{ placement: "bottom-start", ...rest.PopperProps }}
    />
  );
}
