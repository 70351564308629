import { useAppDispatch, useAppSelector } from "redux/hooks";
import DriverForm from "./DriverForm";
import { driverActions } from "redux/slices/smartDriver";

import "moment-timezone";
import DriverService from "services/smartDriver.service";
import useEffectOnce from "hooks/useEffectOnce";
import DragNDrop from "components/templates/DragNDrop";
import { useEffect } from "react";

export default function AddSmartDriver() {
  const { shift, zone, update } = useAppSelector((state) => state.modal.data);
  const drivers = useAppSelector((state) => state.smartDriver.drivers);
  const handleSubmit = async (values: any) => {
    DriverService.updateDriver({ list: values, zone }, zone);
  };
  const dispatch = useAppDispatch();
  // const handleSubmit = async (values: any) => {
  //   DriverService.addDriver({ ...values, zone });
  // };
  useEffect(() => {
    DriverService.getDrivers(zone);
    return () => {
      dispatch(driverActions.setDrivers([]));
    };
  }, [zone, dispatch]);

  return (
    <div>
      <h3>{"Update Driver"}</h3>
      {drivers?.length > 0 && (
        <DragNDrop drivers={drivers} handleSubmit={handleSubmit} />
      )}

      {/* <DriverForm onSubmit={handleSubmit} /> */}
      {/* {update && <UpdateShift />} */}
    </div>
  );
}
