import { useAppDispatch, useAppSelector } from "redux/hooks";
import GoBack from "components/atoms/GoBack";
import Container from "@mui/material/Container";
import Banner from "components/templates/Banner";
import { useNavigate, useParams } from "react-router-dom";
import CircleLoader from "components/atoms/CircleLoader";
import AddProductForm from "./AddProductForm";
import ImageService from "services/image.service";
import productService from "services/product.service";
import UpdateProductForm from "./UpdateProductForm";
import { useEffect } from "react";
import { change } from "redux-form";

export default function AddProduct() {
	const form = 'AddProductForm'
	const dispatch = useAppDispatch()
	const { id } = useParams();
	const navigate = useNavigate();
	const loading = useAppSelector((state) => state.product.loading);

	const brutto = useAppSelector((state) => state.form?.[form]?.values?.brutto);
	const vat = useAppSelector((state) => state.form?.[form]?.values?.vat);


	let newVat = (1+(vat/100))
	let netto = brutto / newVat


	useEffect(()=>{
		if (!vat)dispatch(change(form, "vat", '0'))
		if(brutto && vat) dispatch(change(form, "netto", netto.toFixed(2)));

	},[brutto, dispatch, netto, vat,newVat])

	
	const handleSubmit = async (values: any) => {
		
		let data = {...values}
		
		

		const formData = new FormData();

		let {title,isActive,url,brutto,vat,netto,buying_limit,visibility,product_rank,description} = data

	


		let img = await ImageService.getImageFileFromBlob(url);

		

		formData.append("title", title);
		formData.append("isActive",isActive);
		formData.append("image", img);
	
		formData.append("vat",vat); 
		formData.append("brutto",brutto); 
		formData.append("netto",netto); 
		formData.append("buying_limit", buying_limit);
		formData.append("visibility", visibility);
		formData.append("product_rank", product_rank);
		formData.append("description", description);




		




		

		if (id) productService.updateProduct(`${id}`, formData, navigate);
		else productService.addProduct(formData,navigate)
	};

	return (
		<div>
			<Container>
				<GoBack path="/extras" title="Back to Products" />
				<Banner heading={`${id ? "Update" : "Add"} Product`} />
				

<div className="form">
					{loading && <CircleLoader />}
			
						<AddProductForm onSubmit={handleSubmit} />
				
					{id && <UpdateProductForm id={id} />}
				</div>
			</Container>
		</div>
	);
}
