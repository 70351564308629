import Button from "components/atoms/Button";
import { modalActions } from "redux/slices/modal";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import ScriptService from "services/script.service";

export default function SetSmartScript() {
  const dispatch = useAppDispatch();
  const { status, type, _id } = useAppSelector((state) => state.modal.data);

  return (
    <div>
      {type === "delete" ? (
        <>
          <h3>Remove Script</h3>
          <p>Do you really want to remove this script?</p>
        </>
      ) : status === "start" ? (
        <>
          <h3>Start Script</h3>
          <p>Do you really want to start script?</p>
          <p>The script will start to accept bookings </p>
        </>
      ) : (
        <>
          <h3>Stop Script</h3>
          <p>Do you really want to stop script?</p>
          <p>
            The script will stop to accept bookings{" "}
            {type === "main" &&
              "from Blacklane & Sixt that will start after 24 hours"}
            .
          </p>
        </>
      )}

      <Button
        variant="outlined"
        onClick={() => dispatch(modalActions.closeModal())}
        sx={{ marginRight: "10px" }}
      >
        No
      </Button>
      <Button
        variant="contained"
        onClick={() => {
          if (type === "delete") {
            ScriptService.deleteScript(_id);
          } else {
            ScriptService.stats({
              _id,
              is_start: status === "start" ? true : false,
            });
          }
        }}
      >
        Yes
      </Button>
    </div>
  );
}
