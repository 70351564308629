export { default } from "./DragNDrop";
export type classNames = "First" | "Business" | "Van" | "Bus" | "Extra";
export const BOARD_SECTIONS = {
  First: "First",
  Business: "Business",
  Van: "Van",
  Bus: "Bus",
  Extra: "Extra",
};

export type Task = {
  id: string;
  title: string;
  description: string;
  className: classNames;
  zone: string;
};

export type BoardSections = {
  [name: string]: Task[];
};

export const getTasksByStatus = (tasks: Task[], className: classNames) => {
  return tasks.filter((task) => task.className === className);
};

export const getTaskById = (tasks: Task[], id: string) => {
  return tasks.find((task) => task.id === id);
};

export const initializeBoard = (tasks: Task[]) => {
  const boardSections: BoardSections = {};

  Object.keys(BOARD_SECTIONS).forEach((boardSectionKey) => {
    boardSections[boardSectionKey] = getTasksByStatus(
      tasks,
      boardSectionKey as classNames
    );
  });

  return boardSections;
};

export const findBoardSectionContainer = (
  boardSections: BoardSections,
  id: string
) => {
  if (id in boardSections) {
    return id;
  }

  const container = Object.keys(boardSections).find((key) =>
    boardSections[key].find((item) => item.id === id)
  );
  return container;
};
