import {
  ContactLoadingPayload,
  ContactState,
  SetContactPayload,
  SetContactsPayload,
} from ".";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialContact = {
  contacts: [],
  loading: true,
};

const initialState: ContactState = {
  tab: 0,
  contact: null,
  loading: true,
  open: initialContact,
  close: initialContact,
};

export const contactSlice = createSlice({
  name: "contact",
  initialState,
  reducers: {
    setTab: (state, action: PayloadAction<number>) => {
      state.tab = action.payload;
    },
    setLoader: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setLoading: (state, action: PayloadAction<ContactLoadingPayload>) => {
      const { type, loading } = action.payload;
      state[type].loading = loading;
    },
    setContacts: (state, action: PayloadAction<SetContactsPayload>) => {
      const { type, contacts } = action.payload;

      state[type].contacts = contacts;
    },
    deleteContactRequestById: (
      state,
      action: PayloadAction<SetContactPayload>
    ) => {
      const { type, contact } = action.payload;

      if (!contact?._id) return;

      state[type].contacts = state[type].contacts.filter(
        ({ _id }) => _id !== contact._id
      );
    },
  },
});

const contactReducer = contactSlice.reducer;

export const contactActions = contactSlice.actions;
export default contactReducer;
