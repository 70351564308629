import http from "./http.service";
import Promisable from "./promisable.service";
import { nightPriceActions } from "redux/slices/nightPrice";
import { NavigateFunction } from "react-router-dom";
import { loaderActions } from "redux/slices/loader";
import { getAppDispatch } from "utils/dispatch.util";
import { formLoaderActions } from "redux/slices/formLoader";

const url = "/zone";

const AvailabilityService = {
  addNightPrice: async (data: any, navigate?: NavigateFunction) => {
    const dispatch = getAppDispatch();
    dispatch?.(formLoaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/night-price`, data)
    );

    if (success) {
      navigate?.("/night-price");
    }

    dispatch?.(formLoaderActions.setLoading(false));
    return [success, error];
  },
  updateNightPrice: async (data: any, navigate?: NavigateFunction) => {
    const dispatch = getAppDispatch();
    dispatch?.(formLoaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.patch(`${url}/night-price`, data)
    );

    if (success) {
      navigate?.("/night-price");
    }

    dispatch?.(formLoaderActions.setLoading(false));
    return [success, error];
  },
  getNightPrice: async (id: string) => {
    const dispatch = getAppDispatch();
    dispatch?.(loaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}/night-price/${id}`)
    );

    if (success) {
      const { zone } = success.data.data;
      dispatch?.(nightPriceActions.setNightlPrice(zone));
    } else
      dispatch?.(nightPriceActions.setNightlPrice({ data: "Not Found" }));

    dispatch?.(loaderActions.setLoading(false));
    return [success, error];
  },
  getAllnightPrice: async () => {
    const dispatch = getAppDispatch();
    dispatch?.(nightPriceActions.setLoading(true));

    http.setJWT();
    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}/night-price`)
    );

    if (success) {
      const { zones } = success.data.data;
      dispatch?.(nightPriceActions.setNightPrices(zones));
    }

    dispatch?.(nightPriceActions.setLoading(false));
    return [success, error];
  },
  deleteNightPrices: async (data: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(nightPriceActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/night-price/delete`,data)
    );

    if (success) {
      const { zones } = success.data.data;
      dispatch?.(nightPriceActions.setNightPrices(zones));
    }
    dispatch?.(nightPriceActions.setLoading(false));
    return [success, error];
  },
};

export default AvailabilityService;
