import { SearchHistoryState } from ".";
import { config } from "config";

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const default_page_size = config.PAGE_SIZE;

const initialState: SearchHistoryState = {
  loading: true,
  statsloading: false,
  searchHistory: [],
  searchHistoryStats: [],
  refresh: 0,
  current_filters: {},
  refreshLoader: false,
  filters: { page: 1, page_size: default_page_size },
  count: 0,
};

export const searchHistorySlice = createSlice({
  name: "searchHistory",
  initialState,
  reducers: {
    clear: (state) => {
      state.searchHistoryStats = []
      state.searchHistory = []
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setStatsLoading: (state, action: PayloadAction<boolean>) => {
      state.statsloading = action.payload;
    },
    setSearchHistory: (state, action) => {

      const { searchHistory, count } = action.payload;

      state.searchHistory = searchHistory;
      state.searchHistoryStats = []
      state.count = count;
      state.refreshLoader = false;
    },
    setSearchHistoryStats: (state, action) => {
      const { searchHistory } = action.payload;
      state.searchHistoryStats = searchHistory;
      state.searchHistory = [];
      state.refreshLoader = false;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.refresh += 1;
      state.refreshLoader = true;
      state.filters.page = action.payload;
    },
    refresh: (state) => {
      state.refresh += 1;
      state.refreshLoader = true;
    },
    resetPage: (state) => {
      state.filters.page = 1;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
      state.refreshLoader = true;
    },
    setCurrentFilters: (state, action) => {
      state.current_filters = action.payload;
    },

  },
});

const searchHistoryReducer = searchHistorySlice.reducer;

export const searchHistoryActions = searchHistorySlice.actions;
export default searchHistoryReducer;
