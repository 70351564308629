import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PriorityState } from ".";

const initialState: PriorityState = {
  loading: true,
  priorities: [],
  prioritiesOption: [],
  priority: null,
};

export const prioritySlice = createSlice({
  name: "priority",
  initialState,
  reducers: {
    clear:(state)=>{
      state.prioritiesOption=[]
      state.priorities=[]
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setPriorities: (state, action) => {
      let options: any[] = [];
      let priorities= action.payload
      
      priorities.forEach(({ title, _id }: any,index:any) => {
        options.push({ value:_id, label: title });
      });
      console.log("priorities",priorities,options)
      state.prioritiesOption = options;
      state.priorities = priorities;
    },

    updatePriority: (state, action) => {
      const { id, priority } = action.payload;
      state.priorities.every(({ _id }, i) => {
        if (id === _id) {
          state.priorities[i] = priority;
          return false;
        }
        return true;
      });
    },

    setPriority: (state, action) => {
      state.priority = action.payload;
    },
  },
});

const priorityReducer = prioritySlice.reducer;

export const priorityActions = prioritySlice.actions;
export default priorityReducer;
