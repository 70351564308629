import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CompanyAccountState } from ".";

const initialState: CompanyAccountState = {
	companyAccounts: [],
	companyAccount: null,
	loading: false,
};

export const companyAccountsSlice = createSlice({
	name: "companyAccounts",
	initialState,
	reducers: {
		
		setLoading: (state, action: PayloadAction<boolean>) => {
			state.loading = action.payload;
		},
		// addCustomer: (state, action) => {
		// 	state.customers.push(action.payload);
		// },
		// updateCustomer: (state, action) => {
		// 	const { id, customer } = action.payload;

		// 	state.customers.every(({ _id }, i) => {
		// 		if (id === _id) {
		// 			state.customers[i] = customer;
		// 			return false;
		// 		}

		// 		return true;
		// 	});
		// },
		setCompanyAccounts: (state, action) => {
			state.companyAccounts = action.payload;
		},
		setCompanyAccount: (state, action) => {
			state.companyAccount = action.payload;
		},
	},
});

const companyAccountsReducer = companyAccountsSlice.reducer;

export const companyAccountsActions = companyAccountsSlice.actions;
export default companyAccountsReducer;
