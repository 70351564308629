import ErrorService from "services/error.service";
import OneSignalService from "services/onesignal.service";

const InitializationService = {
	init: () => {
		ErrorService.init();
		OneSignalService.init();
	},
};

export default InitializationService;
