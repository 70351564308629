import { config } from "config";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
	QuotationLoadingPayload,
	QuotationState,
	QuotationType,
	IQuotations,
	NewQuotationPayload,
	RemoveQuotationPayload,
	SetQuotationsPayload,
	SetFiltersPayload,
	SetPagePayload,
	UpdateQuotationPayload,
	
} from ".";

const default_page_size = config.PAGE_SIZE;
const initialQuotation: IQuotations = {
	count: 0,
	quotations: [],
	loading: true,
	current_filters: {},
	filters: { page: 1, page_size: default_page_size },
};
const initialState: QuotationState = {
	tab: 0,
	refresh: 0,
	quotation: null,
	loading: true,
	quotationRatings: [],
	refreshLoader: false,
	open: initialQuotation,
	close: initialQuotation,
};

export const quotationSlice = createSlice({
	name: "quotation",
	initialState,
	reducers: {
		refresh: (state) => {
			state.refresh += 1;
			state.refreshLoader = true;
		},
		setTab: (state, action: PayloadAction<number>) => {
			state.tab = action.payload;
		},
		setPage: (state, action: PayloadAction<SetPagePayload>) => {
			const { type, page } = action.payload;
			state[type].filters.page = page;
			state.refresh += 1;
			state.refreshLoader = true;
		},
		setPageRating: (state, action: PayloadAction<number>) => {
			state.refresh += 1;
			state.refreshLoader = true;
		},
		resetPage: (state, action: PayloadAction<QuotationType>) => {
			const type = action.payload;
			state[type].filters.page = 1;
		},
		setFilters: (state, action: PayloadAction<SetFiltersPayload>) => {
			const { type, data } = action.payload;
			state[type].filters = data;
			state.refreshLoader = true;
		},
		resetFilters: (state, action: PayloadAction<QuotationType>) => {
			const type = action.payload;
			state.refresh += 1;
			state.refreshLoader = true;
			state[type].filters = initialQuotation.filters;
			state[type].current_filters = initialQuotation.current_filters;
		},
		setCurrentFilters: (
			state,
			action: PayloadAction<SetFiltersPayload>
		) => {
			const { type, data } = action.payload;
			state[type].current_filters = data;
		},
		setLoader: (state, action: PayloadAction<boolean>) => {
			state.loading = action.payload;
		},
		setLoading: (state, action: PayloadAction<QuotationLoadingPayload>) => {
			const { type, loading } = action.payload;
			state[type].loading = loading;
			state.refreshLoader=loading
		},
		newQuotation: (state, action: PayloadAction<NewQuotationPayload>) => {
			const { type, quotation } = action.payload;
			state[type].count += 1;
			state[type].quotations.unshift(quotation);
		},
		updateQuotation: (
			state,
			action: PayloadAction<UpdateQuotationPayload>
		) => {
			const { id, type, quotation } = action.payload;
			state[type].quotations.every(({ _id }, i) => {
				if (id === _id) {
					state[type].quotations[i] = quotation;
					return false;
				}
				return true;
			});
		},
		removeQuotation: (
			state,
			action: PayloadAction<RemoveQuotationPayload>
		) => {
			const { id, type } = action.payload;
			state[type].quotations.every(({ _id }, i) => {
				if (id === _id) {
					state[type].count -= 1;
					state[type].quotations.splice(i, 1);
					return false;
				}
				return true;
			});
		},
		setQuotation: (state, action) => {
			state.quotation = action.payload;
		},
		updateQuotationDetails: (state, action) => {
			const quotation = action.payload;
			if (quotation?._id === state.quotation?._id) state.quotation = quotation;
		},
		setQuotations: (state, action: PayloadAction<SetQuotationsPayload>) => {
			const { type, count, quotations } = action.payload;

			state.refreshLoader = false;

			state[type].count = count;
			state[type].quotations = quotations;
		},
		
	
	},
});

const quotationReducer = quotationSlice.reducer;

export const quotationActions = quotationSlice.actions;
export default quotationReducer;
