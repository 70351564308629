import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { NightPriceState } from ".";

const initialState: NightPriceState = {
  count: 0,
  refresh: 0,
  nightPrices: [],
  nightPrice: null,
  loading: true,
};

export const nightPriceSlice = createSlice({
  name: "nightPrice",
  initialState,
  reducers: {

    clear:(state)=>{
      state.nightPrice=null
      state.nightPrices=[]

    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    setNightlPrice: (state, action) => {
      state.nightPrice = action.payload;
    },
    setNightPrices: (state, action) => {
      state.nightPrices = action.payload;
    },

    setDeleteAvailabilities: (state, action) => {
      let myArray = state.nightPrices.filter(
        (obj) => obj._id !== action.payload._id
      );

      state.nightPrices = myArray;
    },
  },
});

const nightPriceReducer = nightPriceSlice.reducer;

export const nightPriceActions = nightPriceSlice.actions;
export default nightPriceReducer;
