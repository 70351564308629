import { ISmart } from ".";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { config } from "config";
const default_page_size = config.PAGE_SIZE;

const initialBooking: ISmart = {
  count: 0,
  list: [],
  booking: null,
  timeLine: {},
  loading: true,
  current_filters: {},
  filters: { page: 1, page_size: default_page_size },
  refresh: 0,
  reset: 0,
  refreshLoader: false,
  stats: false,
  hotRefresh: true,
  timeHotRefresh: true,
  shift: [],
};

const initialState: any = {
  berlin: initialBooking,
  istanbul: initialBooking,
};

export const smartSlice = createSlice({
  name: "smart",
  initialState,
  reducers: {
    refresh: (state, action: PayloadAction<any>) => {
      const zone = action.payload;
      state[zone].refresh += 1;
      state[zone].refreshLoader = true;
    },

    setPage: (state, action: PayloadAction<any>) => {
      const { zone, page } = action.payload;
      state[zone].filters.page = page;
      state[zone].reset += 1;
      state[zone].refreshLoader = true;
    },

    resetPage: (state, action: PayloadAction<any>) => {
      const zone = action.payload;
      state[zone].filters.page = 1;
    },
    setFilters: (state, action: PayloadAction<any>) => {
      const { zone, data } = action.payload;
      state[zone].filters = data;
      state[zone].refreshLoader = true;
    },
    resetFilters: (state: any, action: PayloadAction<any>) => {
      const zone = action.payload;
      state[zone].reset += 1;
      state[zone].refreshLoader = true;
      state[zone].filters = initialState[zone].filters;
      state[zone].current_filters = initialState[zone].current_filters;
    },
    setCurrentFilters: (state, action: PayloadAction<any>) => {
      const { data } = action.payload;
      state.current_filters = data;
    },
    setList: (state, action) => {
      const { zone, list, count, stats } = action.payload;
      state[zone].list = list;
      state[zone].count = count;
      state[zone].stats = stats;
      state[zone].refreshLoader = false;
    },
    setBooking: (state, action) => {
      state.booking = action.payload;
    },
    setTimeLine: (state, action) => {
      const { zone, shift, ...rest } = action.payload;
      state[zone].timeLine = rest;
      state[zone].refreshLoader = false;
      state[zone].hotRefresh = false;
      state[zone].shift = shift;
    },
    clearTimeLine: (state, action) => {
      const { zone } = action.payload;
      state[zone].timeLine = [];
      state[zone].refreshLoader = false;
      state[zone].shift = [];
    },
    addShift: (state, action) => {
      const { zone, shift } = action.payload;
      state[zone].shift.push(shift);
    },
    updateShift: (state, action) => {
      const { zone, shift } = action.payload;
      if (!shift?._id) return;
      state[zone].shift.every(({ _id }: any, i: number) => {
        if (_id == shift._id) {
          state[zone].shift[i] = shift;
          return false;
        }
        return true;
      });
    },
    cancelShift: (state, action) => {
      const { zone, shiftId, shift } = action.payload;
      if (shift) {
        state[zone].shift.every(({ _id }: any, i: number) => {
          if (_id == shift._id) {
            state[zone].shift[i] = shift;
            return false;
          }
          return true;
        });
      } else {
        state[zone].shift = state[zone].shift.filter(
          ({ _id }: any) => {
            if (shiftId && shiftId == _id) {
              return false;
            }
            return true;
          }
        );
      }
    },
    setStats: (state, action) => {
      state.stats = action.payload;
      state.refreshLoader = false;
    },
    setLoading: (state, action: PayloadAction<any>) => {
      const { zone, load } = action.payload;
      state[zone].loading = load;
      state[zone].refreshLoader = load;
      state[zone].hotRefresh = false;
    },
  },
});

const smartReducer = smartSlice.reducer;

export const smartActions = smartSlice.actions;
export default smartReducer;
