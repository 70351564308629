import http from "./http.service";
import Promisable from "./promisable.service";
import { getAppDispatch } from "utils/dispatch.util";
import { formLoaderActions } from "redux/slices/formLoader";
import { priorityActions } from "redux/slices/priority/prioritySlice";

const url = "/priority";

const PriorityService = {
  getPriority: async (id: string) => {
    const dispatch = getAppDispatch();
    dispatch?.(priorityActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}/${id}`)
    );

    if (success) {
      const { priority } = success.data.data;
      dispatch?.(priorityActions.setPriority(priority));
    } else dispatch?.(priorityActions.setPriority({ data: "Not Found" }));

    dispatch?.(priorityActions.setLoading(false));
    return [success, error];
  },
  getAll: async () => {
    const dispatch = getAppDispatch();
    dispatch?.(priorityActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}`)
    );

    if (success) {
      const { priorities } = success.data.data;
      dispatch?.(priorityActions.setPriorities(priorities));
    }

    dispatch?.(priorityActions.setLoading(false));
    return [success, error];
  },
  updateById: async (id: string, data: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(formLoaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.patch(`${url}/${id}`, data)
    );

    if (success) {
      const { priority } = success.data.data;
      dispatch?.(priorityActions.setPriority(priority));
      dispatch?.(priorityActions.updatePriority({ id, priority }));
    }

    dispatch?.(formLoaderActions.setLoading(false));
    return [success, error];
  },
};

export default PriorityService;
