import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SpecialPriceState } from ".";

const initialState: SpecialPriceState = {
  count: 0,
  refresh: 0,
  specialPrices: [],
  specialPrice: null,
  loading: true,
};

export const specialPriceSlice = createSlice({
  name: "specialPrice",
  initialState,
  reducers: {

    clear:(state)=>{
      state.specialPrice=null
      state.specialPrices=[]

    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    setSpecialPrice: (state, action) => {
      state.specialPrice = action.payload;
    },
    setSpecialPriceClasses: (state, action) => {
      state.specialPrices = action.payload;
    },

    setDeleteAvailabilities: (state, action) => {
      let myArray = state.specialPrices.filter(
        (obj) => obj._id !== action.payload._id
      );

      state.specialPrices = myArray;
    },
  },
});

const specialPriceReducer = specialPriceSlice.reducer;

export const specialPriceActions = specialPriceSlice.actions;
export default specialPriceReducer;
