import { useEffect } from "react";
import { useAppSelector, useAppDispatch } from "redux/hooks";
import SmartService from "services/smart.service";
import DateService from "utils/date.util";
import ObjectService from "utils/object.util";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import ClassChip from "components/atoms/ClassChip";
import Chip from "components/atoms/Chip";
import { Link } from "react-router-dom";
import { modalActions } from "redux/slices/modal";
import ScriptService from "services/script.service";
import { TableHead } from "@mui/material";
import TableLoadingWrapper from "components/templates/TableLoadingWrapper";
import { StyledTableCell, StyledTableRow } from "components/templates/Tables";
import moment from "moment";
export default function UpdateBookingStatus() {
  const { _id, type, source, zone, mc_market } = useAppSelector(
    (state) => state.modal.data
  );
  const loading = useAppSelector((state) => state.modal.loading);

  const booking = useAppSelector((state) => state.smart.booking);
  const data = useAppSelector((state) => state.script.bookings);
  let { bookings, count } = data || {};
  const dispatch = useAppDispatch();

  const today = moment().startOf("day");
  const yesterday = moment().subtract(1, "days").startOf("day");
  const startOfWeek = moment().isoWeekday(1).startOf("day");
  const isSameDay = (date1: any, date2: any) => {
    return date1.isSame(date2, "day");
  };
  const todayBookings = bookings?.filter((booking: any) =>
    isSameDay(moment(booking.createdAt), today)
  )?.length;
  const yesterdayBookings = bookings?.filter((booking: any) =>
    isSameDay(moment(booking.createdAt), yesterday)
  )?.length;
  const thisWeekBooking = bookings?.filter((booking: any) =>
    moment(booking.createdAt).isSameOrAfter(startOfWeek, "day")
  )?.length;
  useEffect(() => {
    if (_id) {
      if (type == "script") ScriptService.getScriptBookings(_id, zone);
      else SmartService.getOne(_id, source, mc_market);
    }
  }, [_id]);
  console.log("bookings", bookings);
  return type == "script" ? (
    <div>
      <h3>
        Script Bookings({source}) - {count} Accepted
      </h3>
      <div
        style={{
          display: "flex",
          padding: "0px 18px",
          justifyContent: "space-between",
          boxShadow: "0px 0px 3px 0px lightgray",
        }}
      >
        <h5 style={{ margin: "10px 0px" }}>Today: {todayBookings || 0}</h5>
        <h5 style={{ margin: "10px 0px" }}>
          Yesterday: {yesterdayBookings || 0}
        </h5>
        <h5 style={{ margin: "10px 0px" }}>
          This Week: {thisWeekBooking || 0}
        </h5>
      </div>
      <TableContainer>
        <Table
          sx={{
            minWidth: "100%",
            borderCollapse: "separate",
            borderBottomWidth: "0px",
            borderSpacing: "0 10px",
          }}
          aria-label="customized table"
        >
          <TableLoadingWrapper
            loading={loading || false}
            length={loading || bookings?.length}
            coloumns={2}
            message={`We couldn't find any bookings`}
          >
            <TableHead>
              <TableRow>
                <StyledTableCell>Booking Id</StyledTableCell>
                <StyledTableCell sx={{ whiteSpace: "nowrap" }}>
                  Accept At
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {bookings?.map((booking: any, index: number) => (
                <StyledTableRow key={index}>
                  <StyledTableCell sx={{ whiteSpace: "nowrap" }}>
                    {source === "TRANSFEERO" ? (
                      <a
                        style={{
                          textDecoration: "none",
                          color: "rgb(0 0 0 / 70%)",
                        }}
                        href={`https://control.transfeero.com/booking/${booking.bookingId}`}
                        rel="noreferrer"
                        target="_blank"
                      >
                        {booking.bookingId}
                      </a>
                    ) : (
                      <a
                        style={{
                          textDecoration: "none",
                          color: "rgb(0 0 0 / 70%)",
                        }}
                        href={`https://dcp.orange.sixt.com/manageRides?activeTab=ALL_RIDES&page=1&rideId=${booking.bookingId}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {booking.bookingId}
                      </a>
                    )}
                  </StyledTableCell>
                  <StyledTableCell sx={{ whiteSpace: "nowrap" }}>
                    {DateService.getServerDateString(booking.createdAt)}
                    <br />
                    {DateService.getServerTimeString(booking.createdAt)}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </TableLoadingWrapper>
        </Table>
      </TableContainer>
    </div>
  ) : (
    <div>
      <h3> Booking Details</h3>

      {booking && (
        <>
          <TableContainer>
            <Table aria-label="simple table">
              <TableBody>
                <TableRow>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ whiteSpace: "nowrap" }}
                  >
                    Booking Id:
                  </TableCell>
                  <TableCell>
                    {booking.source === "TRANSFEERO" && (
                      <a
                        style={{
                          textDecoration: "none",
                          color: "rgb(0 0 0 / 70%)",
                        }}
                        href={`https://control.transfeero.com/booking/${booking.booking_number}`}
                        rel="noreferrer"
                        target="_blank"
                      >
                        {booking.booking_number}
                      </a>
                    )}
                    {booking.source === "TALIXO" && (
                      <a
                        style={{
                          textDecoration: "none",
                          color: "rgb(0 0 0 / 70%)",
                        }}
                        href={`https://talixo.com/manager/job/${booking.booking_number}`}
                        rel="noreferrer"
                        target="_blank"
                      >
                        {booking.booking_number}
                      </a>
                    )}
                    {booking.source == "BLACKLANE" && (
                      <a
                        style={{
                          textDecoration: "none",
                          color: "rgb(0 0 0 / 70%)",
                        }}
                        href={`https://partner.blacklane.com/${
                          booking.current_status == "Finished"
                            ? "finished-detail"
                            : "planned-detail"
                        }/?id=${booking.source_id}`}
                        target="_blank"
                      >
                        {booking.booking_number}
                      </a>
                    )}
                    {booking.source == "MYCHAUFFEUR" && (
                      <Link
                        style={{
                          textDecoration: "none",
                          color: "rgb(0 0 0 / 70%)",
                        }}
                        to={`/booking-details/${booking.source_id}`}
                        onClick={() => {
                          dispatch(modalActions.closeModal());
                        }}
                      >
                        {booking.booking_number}
                      </Link>
                    )}
                    {source == "PRIVATE" && (
                      <Link
                        style={{
                          textDecoration: "none",
                          color: "rgb(0 0 0 / 70%)",
                        }}
                        to={`/booking-details/${booking._id}`}
                        onClick={() => {
                          dispatch(modalActions.closeModal());
                        }}
                      >
                        {booking.prv_booking_number}
                      </Link>
                    )}
                    {booking.source == "SIXT" && (
                      <a
                        style={{
                          textDecoration: "none",
                          color: "rgb(0 0 0 / 70%)",
                        }}
                        href={`https://dcp.orange.sixt.com/manageRides?activeTab=${
                          booking.current_status == "Finished"
                            ? "COMPLETED"
                            : "ALL_RIDES"
                        }&page=1&rideId=${booking.booking_number}`}
                        target="_blank"
                      >
                        {booking.booking_number}
                      </a>
                    )}

                    <small> ({booking.source || source})</small>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ whiteSpace: "nowrap" }}
                  >
                    Date & Time:
                  </TableCell>
                  <TableCell>
                    {DateService.getSmartDateString(booking.date)}
                    <br />
                    {DateService.getSmartTimeString(booking.date)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ whiteSpace: "nowrap" }}
                  >
                    Customer:
                  </TableCell>
                  <TableCell>
                    {ObjectService.getFullName(booking.user_details)} <br />
                    {booking?.user_details.phone || booking?.user_details.email}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ whiteSpace: "nowrap" }}
                  >
                    Route:
                  </TableCell>
                  <TableCell>
                    <div>
                      <div style={{ marginBottom: "6px" }}>
                        {booking.pickup.address}
                      </div>
                      {booking?.booking_type === "hourly" ||
                      booking?.trip_type === "hourly" ? (
                        <div style={{ fontSize: "12px", color: "red" }}>
                          {(Number(booking.duration) / 60).toFixed(0)} hours
                        </div>
                      ) : (
                        <>
                          <div style={{ marginBottom: "6px" }}>
                            {booking.destination.address}
                          </div>{" "}
                          <span style={{ fontSize: "12px", color: "red" }}>
                            {booking.distance && (
                              <span>{booking.distance} Km</span>
                            )}
                            {booking?.duration > 0 && (
                              <span>
                                {" "}
                                (estimated time ~{booking.duration?.toFixed(
                                  0
                                )}{" "}
                                mins)
                              </span>
                            )}
                          </span>
                        </>
                      )}
                    </div>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ whiteSpace: "nowrap" }}
                  >
                    Class:
                  </TableCell>
                  <TableCell>
                    <ClassChip label={booking.class_name} size="small" />
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ whiteSpace: "nowrap" }}
                  >
                    Price:
                  </TableCell>
                  <TableCell>{Number(booking.price)?.toFixed?.(2)}€</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ whiteSpace: "nowrap" }}
                  >
                    Accepted by:
                  </TableCell>
                  <TableCell>
                    <div>
                      {booking.is_assigned ? (
                        <>
                          {booking.driver_details ? (
                            <>
                              {ObjectService.getFullName(
                                booking.driver_details
                              )}
                            </>
                          ) : (
                            <span style={{ color: "rgb(183, 33, 54)" }}>
                              No chauffeur details
                            </span>
                          )}
                        </>
                      ) : (
                        <span style={{ color: "rgb(183, 33, 54)" }}>
                          No chauffeur assigned
                        </span>
                      )}
                    </div>
                  </TableCell>
                </TableRow>
                {source != "PRIVATE" && (
                  <TableRow>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ whiteSpace: "nowrap" }}
                    >
                      Status:
                    </TableCell>
                    <TableCell>
                      <Chip status={booking?.current_status} />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </div>
  );
}
