import { IDashboard } from ".";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialData: IDashboard = {
  refresh: 0,
  loading: false,
  refreshLoader: false,
  data:{},
  current_filters: {},
	filters: { page: 1, page_size: 10 },
};
const initialState: any = {
  bookings: initialData,
  users: initialData,
  userList: initialData,
  tab:'bookings',

};
export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setTab: (state, action: PayloadAction<'users'|'bookings'>) => {
      state.tab = action.payload;
    },
    setLoader: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setLoading: (state, action: PayloadAction<{type:'users'|'bookings'|'userList',load:boolean}>) => {
      const {type,load}=action.payload;
      state[type].loading = load
      state[type].refreshLoader = load
    },
    setData: (state, action: PayloadAction<any>) => {
      const {type,data}=action.payload;
      state[type].loading = false;
      state[type].data = data
    },

		refresh: (state,action: PayloadAction<any>) => {
			const type  = action.payload;
			state[type].refresh += 1;
			state[type].refreshLoader = true;
		},
		
		setPage: (state, action: PayloadAction<any>) => {
			const {type, page } = action.payload;
			state[type].filters.page = page;
			state[type].reset += 1;
			state[type].refreshLoader = true;
		},
	
		resetPage: (state, action: PayloadAction<any>) => {
			const type  = action.payload;
			state[type].filters.page = 1;
		},
		setFilters: (state, action: PayloadAction<any>) => {
			const {type,  data } = action.payload;
			state[type].filters = data;
			state[type].refreshLoader = true;
		},
		resetFilters: (state:any,action: PayloadAction<any>) => {
			const type  = action.payload;
			state[type].reset += 1;
			state[type].refreshLoader = true;
			state[type].filters = initialState[type].filters;
			state[type].current_filters =initialState[type].current_filters;
		},
		setCurrentFilters: (
			state,
			action: PayloadAction<any>
		) => {
			const {data } = action.payload;
			state.current_filters = data;
		},

  },
});

const dashboardReducer = dashboardSlice.reducer;

export const dashboardActions = dashboardSlice.actions;
export default dashboardReducer;
