import { config } from "config";
import { DeleteUserPayload, IUsers, SetFiltersPayload, SetPagePayload, SetUsersPayload, UpdateUserPayload, UserState, userType } from ".";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SelectOption } from "components/atoms/Select";

const default_page_size = config.PAGE_SIZE;

const initialUsers: IUsers = {
	count: 0,
  user:null,
  userOptions:[],
  users:[],
	loading: true,
	current_filters: {},
	filters: { page: 1, page_size: default_page_size },
};
const initialState: UserState = {
	tab: 0,
	refresh: 0,
	user: null,
	loading: true,
  userOptions:[],
	refreshLoader: false,
	activeUsers: initialUsers,
	deletedUsers: initialUsers,
  

};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setTab: (state, action: PayloadAction<number>) => {
			state.tab = action.payload;
		},
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    clear_search: (state,action: PayloadAction<userType>) => {
      const type=action.payload
      state[type].users = [];
      state.userOptions = [];
    },
    updateUser: (state, action:PayloadAction<UpdateUserPayload>) => {
      const { id, user ,type} = action.payload;
      state[type].users.every(({ _id }, i) => {
        if (id === _id) {
          state[type].users[i] = user;
          return false;
        }
        return true;
      });
    },
    deleteUserById: (state, action:PayloadAction<DeleteUserPayload>) => {
      const {id,type} = action.payload;
      state[type].users.every(({ _id }, i) => {
        if (id === _id) {
          state[type].count -= 1;
          state[type].users.splice(i, 1);
          return false;
        }
        return true;
      });
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setUsers: (state, action: PayloadAction<SetUsersPayload>) => {
      const { count, users ,type} = action.payload;
      state[type].count = count;
      state[type].users = users;
      state.refreshLoader = false;
    },
    setSearchUsers: (state, action: PayloadAction<SetUsersPayload>) => {
      const { count, users,type } = action.payload;
      state[type].count = count;
      state.refreshLoader = false;

      let options: SelectOption[] = [];
      users.forEach(({ _id, first_name, last_name,phone }: any) =>
        options.push({ value: _id, label: first_name + " " + last_name +" - " +phone })
      );
      state[type].users = users;
      state.userOptions = options;
    },
    setPage: (state, action: PayloadAction<SetPagePayload>) => {
      const {type,page} = action.payload
      state.refresh += 1;
      state.refreshLoader = true;
      state[type].filters.page = page;
    },
    refresh: (state) => {
      state.refresh += 1;
      state.refreshLoader = true;
    },
    resetPage: (state,action: PayloadAction<userType>) => {
			const type = action.payload;
      state[type].filters.page = 1;
    },
    setFilters: (state, action: PayloadAction<SetFiltersPayload>) => {
			const { type, data } = action.payload;
      state[type].filters = data;
      state.refreshLoader = true;
    },
    setCurrentFilters: (state, action: PayloadAction<SetFiltersPayload>) => {
			const { type, data } = action.payload;

      state[type].current_filters = data;
    },
    resetFilters: (state,action: PayloadAction<userType>) => {
			const type = action.payload;
      state.refresh += 1;
      state.refreshLoader = true;
      state[type].filters = initialUsers.filters;
      state[type].current_filters = initialUsers.current_filters;
    },
  },
});

const userReducer = userSlice.reducer;

export const userActions = userSlice.actions;
export default userReducer;
