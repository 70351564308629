import Promisable from "./promisable.service";
import { smartActions } from "redux/slices/smart";
import { getAppDispatch } from "utils/dispatch.util";
import http from "./http.service";
import { modalActions } from "redux/slices/modal";
import ObjectService from "utils/object.util";

let url = "/smart";
const SmartService = {
  get_list: async (data: any, query: any) => {
    let zone = data.zone;
    const dispatch = getAppDispatch();
    if (data?.all) dispatch?.(modalActions.setLoading(true));
    else dispatch?.(smartActions.setLoading({ load: true, zone }));
    http.setJWT();
    let endpoint =
      data.source == "BLACKLANE_Finished"
        ? `/blacklane/list${query}`
        : `${url}/list${query}`;
    const [success, error]: any = await Promisable.asPromise(
      http.post(endpoint, data)
    );
    if (success.data) {
      const { list, count, stats } = success.data.data;
      if (data?.all) {
        dispatch?.(
          modalActions.updateData({
            table_data: list.map((booking: any) => {
              let trip: any = {
                ID: booking.booking_number,
                Date: booking.date,
                "Customer Name": booking.booking_for_someone
                  ? `${ObjectService.getFullName(booking.booking_for_details)}`
                  : `${ObjectService.getFullName(booking.user_details)}`,
                "Customer Phone": booking.booking_for_someone
                  ? booking.booking_for_details.phone
                  : booking.user_details.phone,
                "Customer Email": booking?.user_details?.email,
                "Trip Type":
                  booking.booking_type == "transfer"
                    ? "trip"
                    : booking.booking_type,
                Pickup: booking.pickup.address,
                Destination: booking.destination.address,
                Duration: booking.duration,
                Distance: booking.distance,
                "Service Class": booking.class_name,
                "Company Name": booking.partner_details?.company_name,
                "Driver Name": `${ObjectService.getFullName(
                  booking.driver_details
                )}`,
                Price: Number(booking.price)?.toFixed?.(2),
              };
              if (data.source && data.source !== "BLACKLANE_Finished") {
                trip["Source"] = data.source;
                trip["Current Status"] = booking.current_status;
              }

              return trip;
            }),
          })
        );
      } else {
        dispatch?.(smartActions.setList({ zone, list, count, stats }));
      }
    }
    if (data?.all) dispatch?.(modalActions.setLoading(false));
    else dispatch?.(smartActions.setLoading({ load: false, zone }));
  },

  getOne: async (id: string, source: string, mc_market?: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(modalActions.setLoading(true));
    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.get(url + `/${id}?source=${source}&mc_market=${mc_market}`)
    );
    if (success?.data) {
      dispatch?.(smartActions.setBooking(success.data?.data));
    }

    dispatch?.(modalActions.setLoading(false));
  },
  getTimeLine: async (zone: any, hotRefresh?: boolean) => {
    const dispatch = getAppDispatch();
    dispatch?.(smartActions.setLoading({ load: true, zone }));
    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.get(url + `/timeLine/${zone}?hotRefresh=${hotRefresh}`)
    );
    if (success?.data) {
      const { groups, items, shift } = success.data.data;
      dispatch?.(smartActions.setTimeLine({ zone, groups, items, shift }));
    }

    dispatch?.(smartActions.setLoading({ load: false, zone }));
  },
  addShift: async (data: any, zone: string) => {
    const dispatch = getAppDispatch();
    dispatch?.(modalActions.setLoading(true));
    http.setJWT();
    const [success, error]: any = await Promisable.asPromise(
      http.post(`/shift`, data)
    );
    if (success.data) {
      const { shift } = success.data.data;
      dispatch?.(smartActions.addShift({ shift, zone }));
      dispatch?.(modalActions.closeModal());
      dispatch?.(modalActions.setLoading(false));
    }
  },
  updateShift: async (id: any, data: any, zone: string) => {
    const dispatch = getAppDispatch();
    dispatch?.(modalActions.setLoading(true));
    http.setJWT();
    const [success, error]: any = await Promisable.asPromise(
      http.patch(`/shift/${id}`, data)
    );
    if (success.data) {
      const { shift } = success.data.data;
      dispatch?.(smartActions.updateShift({ shift, id, zone }));
      dispatch?.(modalActions.closeModal());
      dispatch?.(modalActions.setLoading(false));
    }
    dispatch?.(modalActions.setLoading(false));
  },
  cancelShift: async (id: any, zone: string, everyday?: any, shiftId?: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(modalActions.setLoading(true));
    http.setJWT();
    const [success, error]: any = await Promisable.asPromise(
      http.delete(`/shift/${id}?everyday=${everyday}`)
    );
    if (success.data) {
      const { shift } = success.data.data;

      dispatch?.(
        smartActions.cancelShift({ slotId: id, shiftId, zone, shift })
      );
      dispatch?.(modalActions.closeModal());
      dispatch?.(modalActions.setLoading(false));
    }
    dispatch?.(modalActions.setLoading(false));
  },
  cancelAllShift: async (id: any, zone: string) => {
    const dispatch = getAppDispatch();
    dispatch?.(modalActions.setLoading(true));
    http.setJWT();
    const [success, error]: any = await Promisable.asPromise(
      http.delete(`/shift/all/${id}`)
    );
    if (success.data) {
      dispatch?.(smartActions.cancelShift({ driverId: id, zone }));
      dispatch?.(modalActions.closeModal());
      dispatch?.(modalActions.setLoading(false));
    }
  },
};

export default SmartService;
