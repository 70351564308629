import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { StyledTableCell, StyledTableRow } from "components/templates/Tables";
import {
  Table,
  TableRow,
  TableBody,
  TableHead,
  TableContainer,
  Typography,
  Box,
} from "@mui/material";
import TableLoadingWrapper from "components/templates/TableLoadingWrapper";
import nightPriceServices from "services/nightPrice.service";
import DateService from "utils/date.util";
import Button from "components/atoms/Button";
import BlockComponent from "components/organisms/BlockComponent/BlockComponent";
import { useNavigate } from "react-router-dom";
import CheckboxRedux from "components/molecules/RoleCheckboxRedux";


export default function NightPricesList() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate()
  const nightPrices = useAppSelector((state) => state.nightPrice.nightPrices);
  const loading = useAppSelector((state) => state.nightPrice.loading);
  const [deleteArray, setDeleteArray] = useState<any>([])
  const [alldeleteArray, setAllDeleteArray] = useState(false)

  useEffect(() => {
    nightPriceServices.getAllnightPrice();
  }, []);

  const handleChange = (e: any) => {
    const deleteArrayCopy = [...deleteArray]
    const index = deleteArrayCopy.indexOf(e.id)
    if (index >= 0) {
      deleteArrayCopy.splice(index, 1)
    } else {
      deleteArrayCopy.push(e.id)
    }
    setDeleteArray(deleteArrayCopy)
  }
  const deleteAll = async () => {
    if (alldeleteArray) {
      setAllDeleteArray(!alldeleteArray)
      setDeleteArray([])
      return
    }
    setAllDeleteArray(!alldeleteArray)
    let arrayIds: any[] = []
    for await (const zone_id of nightPrices) {
      arrayIds.push(zone_id?._id)

    }
    setDeleteArray(arrayIds)
  }
  const handleDeletenightPrices = async () => {
    const payload = {
      zonesList: deleteArray
    }
    const [success] = await nightPriceServices.deleteNightPrices(payload)
    if (success) {
      setAllDeleteArray(false)
      setDeleteArray([])

    }
  }

  return (
    <>
    {nightPrices.length>0 &&
      <BlockComponent permission={"price:write"}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <CheckboxRedux
            name={``}
            info={{}}
            checked={alldeleteArray}
            handleChange={deleteAll}
            label={"All"}
          />
          <Button variant={"contained"} color="error" onClick={handleDeletenightPrices}>Delete</Button>
        </Box>
      </BlockComponent>
      }
      <TableContainer>
        <Table
          aria-label="customized table"
          sx={{
            minWidth: "100%",
            borderSpacing: "0 10px",
            borderBottomWidth: "0px",
            borderCollapse: "separate",
          }}
        >
          <TableLoadingWrapper
            coloumns={6}
            loading={loading}
            length={loading ? 0 : nightPrices.length}
            message="There are nothing to show now"
          >
            <TableHead>
              <TableRow>
                <StyledTableCell>Zone</StyledTableCell>
                <StyledTableCell>Time</StyledTableCell>
                <StyledTableCell>Percentage</StyledTableCell>
                <BlockComponent permission={"availability:write"}>

                  <StyledTableCell align="center">Action</StyledTableCell>
                </BlockComponent>
              </TableRow>
            </TableHead>
            <TableBody>
              {nightPrices.map((prices) => (
                <StyledTableRow key={prices._id}>
                  <StyledTableCell sx={{ textTransform: "capitalize" }}>
                    <BlockComponent permission={"price:write"}>
                      <CheckboxRedux
                        name={`${prices._id}`}
                        info={{ id: prices._id }}
                        checked={deleteArray.includes(prices._id)}
                        handleChange={handleChange}
                      />
                    </BlockComponent>
                    {prices.name}
                  </StyledTableCell>
                  <StyledTableCell>
                      <>
                        {DateService.getTimeString(
                          prices.night_interval?.start_time
                        )}{" "}
                        -
                         &nbsp;
                        {DateService.getTimeString(
                          prices.night_interval?.end_time
                        )}
                        <br />
                      </>
                  </StyledTableCell>
                  <StyledTableCell>
                        {prices.night_interval.percentage + "%"}

                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <BlockComponent permission={"price:write"}>
                      <Button
                        size="small"
                        variant="text"
                        onClick={() =>
                          navigate(`/update-night-price/${prices._id}`)
                        }
                      >
                        Edit
                      </Button>
                    </BlockComponent>

                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </TableLoadingWrapper>
        </Table>
      </TableContainer>
    </>
  );
}
