import { Container, Grid } from "@mui/material";
import GoBack from "components/atoms/GoBack";
import Banner from "components/templates/Banner";
import UpdatePriority from "pages/UpdatePriority";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import PriorityService from "services/priority.service";

export default function PriorityDetails() {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.priority.loading);
  const priority = useAppSelector((state) => state.priority.priority);

  useEffect(() => {
    PriorityService.getPriority(`${id}`);
  }, [id, dispatch]);

  return (
    <div>
      <Container maxWidth="xl">
        <GoBack path="/priority" title="Back to Priorities" />
        <Banner heading="Partner Priority" />
        {loading ? (
          "Loading..."
        ) : !priority || priority?.data === "Not Found" ? (
          "Priority Not Found!"
        ) : (
          <Grid container>
            <div className="form">
              <UpdatePriority />
            </div>
          </Grid>
        )}
      </Container>
    </div>
  );
}
