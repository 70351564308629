import {
  PartnerRequestState,
  SetPartnerRequestsPayload,
  PartnerRequestLoadingPayload,
  SetPartnerRequestPayload,
  SetFiltersPayload,
  PartnerRequestType,
} from ".";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialPartnerRequest = {
  partnerRequests: [],
  loading: true,
  current_filters: {},
  filters: {},
};
const initialState: PartnerRequestState = {
  tab: 0,
  partnerRequest: null,
  loading: false,
  open: initialPartnerRequest,
  close: initialPartnerRequest,
};

export const partnerRequestSlice = createSlice({
  name: "partnerRequest",
  initialState,
  reducers: {
    setTab: (state, action: PayloadAction<number>) => {
      state.tab = action.payload;
    },
    setLoader: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setLoading: (
      state,
      action: PayloadAction<PartnerRequestLoadingPayload>
    ) => {
      const { type, loading } = action.payload;
      state[type].loading = loading;
    },
    setPartnerRequests: (
      state,
      action: PayloadAction<SetPartnerRequestsPayload>
    ) => {
      const { type, partnerRequests } = action.payload;

      state[type].partnerRequests = partnerRequests;
    },
    updatePartnerRequest: (
      state,
      action: PayloadAction<SetPartnerRequestPayload>
    ) => {
      const { type, partnerRequest } = action.payload;

      if (!partnerRequest?._id) return;

      state[type].partnerRequests = state[type].partnerRequests.filter(
        ({ _id }) => _id !== partnerRequest._id
      );
    },
    setFilters: (state, action: PayloadAction<SetFiltersPayload>) => {
      const { type, data } = action.payload;
      state[type].filters = data;
      // state.refreshLoader = true;
    },
    resetFilters: (state, action: PayloadAction<PartnerRequestType>) => {
      const  type  = action.payload;
      // state.refresh += 1;
      // state.refreshLoader = true;
      state[type].filters = initialPartnerRequest.filters;
      state[type].current_filters = initialPartnerRequest.current_filters;
    },
    setCurrentFilters: (
      state,
      action: PayloadAction<SetFiltersPayload>
    ) => {
      const { type, data } = action.payload;
      state[type].current_filters = data;
    },
  },
});

const partnerRequestReducer = partnerRequestSlice.reducer;

export const partnerRequestActions = partnerRequestSlice.actions;
export default partnerRequestReducer;
