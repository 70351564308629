import UpdatePayRoll from "pages/PayRoll/UpdatePayRoll";
import { lazy } from "react";
import { MODAL } from "redux/slices/modal";
import SetSmartScript from "./SetSmartScript";
import BookingDetails from "./BookingDetails";
import AddShift from "./AddShift";
import AddSmartDriver from "./AddSmartDriver";

const AddNotes = lazy(() => import("./AddNotes"));
const DeleteUser = lazy(() => import("./DeleteUser"));
const AddPayment = lazy(() => import("./AddPayment"));
const PayInvoice = lazy(() => import("./PayInvoice"));
const HandleStatus = lazy(() => import("./HandleStatus"));
const DisableModal = lazy(() => import("./DisableModal"));
const ChildrenSeats = lazy(() => import("./ChildrenSeats"));
const AssignBooking = lazy(() => import("./AssignBooking"));
const AssignPrice = lazy(() => import("./AssignPrice"));
const CancelBooking = lazy(() => import("./CancelBooking"));
const UploadDocument = lazy(() => import("./UploadDocument"));
const ConfirmationForm = lazy(() => import("./ConfirmationForm"));
const ConfirmationModal = lazy(() => import("./ConfirmationModal"));
const ExportTableOptions = lazy(() => import("./ExportTableOptions"));
const UpdateBookingStatus = lazy(() => import("./UpdateBookingStatus"));
const MarketTransferPopup = lazy(() => import("./MarketTransferPopup"));
const UpdateBookingBillingAddress = lazy(
  () => import("./UpdateBookingBillingAddress")
);
const SearchHistoryStats = lazy(() => import("./SearchHistoryStats"));
const AssignVehicle = lazy(() => import("./AssignVehicle"));
const AssignFavDriver = lazy(() => import("./AssignFavDriver"));
const UpdateDynamics = lazy(() => import("./UpdateDynamics"));
const UpdatePaymentStatus = lazy(() => import("./UpdatePaymentStatus"));

export { default } from "./AppModal";

export type ModalMapper = {
  [key in MODAL]: "" | JSX.Element;
};

export const modalMapper: ModalMapper = {
  ADD_NOTES: <AddNotes />,
  PAY_INVOICE: <PayInvoice />,
  DELETE_USER: <DeleteUser />,
  ADD_PAYMENT: <AddPayment />,
  HANDLE_STATUS: <HandleStatus />,
  DISABLE_DRIVER: <DisableModal />,
  CHILDREN_SEATS: <ChildrenSeats />,
  ASSIGN_BOOKING: <AssignBooking />,
  ASSIGN_PRICE: <AssignPrice />,
  CANCEL_BOOKING: <CancelBooking />,
  UPLOAD_DOCUMENT: <UploadDocument />,
  CONFIRMATION_FORM: <ConfirmationForm />,
  MARKET_TRANSFER: <MarketTransferPopup />,
  CONFIRMATION_MODAL: <ConfirmationModal />,
  EXPORT_TABLE_OPTIONS: <ExportTableOptions />,
  UPDATE_BOOKING_STATUS: <UpdateBookingStatus />,
  UPDATE_BOOKING_BILLING_ADDRESS: <UpdateBookingBillingAddress />,
  UPDATE_PAYROLL: <UpdatePayRoll />,
  SEARCH_HISTORY_STATS: <SearchHistoryStats />,
  SET_SMART_SCRIPT: <SetSmartScript />,
  BOOKING_DETAILS: <BookingDetails />,
  ASSIGN_PARTNER_VEHICLE: <AssignVehicle />,
  ASSIGN_FAV_DRIVER: <AssignFavDriver />,
  UPDATE_DYNAMICS: <UpdateDynamics />,
  Add_SHIFT: <AddShift />,
  SMART_DRIVER: <AddSmartDriver />,
  UPDATE_PAYMENT_STATUS: <UpdatePaymentStatus />,

};
