import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";

import dashboardReducer from "./slices/dashboard";
import authReducer from "./slices/auth";
import userReducer from "./slices/user";
import zoneReducer from "./slices/zone";
import classReducer from "./slices/class";
import modalReducer from "./slices/modal";
import modelReducer from "./slices/model";
import eventReducer from "./slices/event";
import driverReducer from "./slices/driver";
import loaderReducer from "./slices/loader";
import careerReducer from "./slices/career";
import contactReducer from "./slices/contact";
import invoiceReducer from "./slices/invoice";
import partnerReducer from "./slices/partner";
import companyReducer from "./slices/company";
import vehicleReducer from "./slices/vehicle";
import pricingReducer from "./slices/pricing";
import bookingReducer from "./slices/booking";
import paymentReducer from "./slices/payment";
import campaignReducer from "./slices/campaign";
import currencyReducer from "./slices/currency";
import timeZoneReducer from "./slices/timeZone";
import commissionReducer from "./slices/commission";
import formLoaderReducer from "./slices/formLoader";
import smartReducer from "./slices/smart/smartSlice";
import payRollReducer from "./slices/payroll/payroll";
import { roleReducer } from "./slices/role/roleSlice";
import notificationReducer from "./slices/notification";
import searchHistoryReducer from "./slices/searchHistory";
import productReducer from "./slices/product/productSlice";
import companyInvoiceReducer from "./slices/companyInvoice";
import reportedProblemReducer from "./slices/reportedProblem";
import nightPriceReducer from "./slices/nightPrice/nightPriceSlice";
import specialPriceReducer from "./slices/specialPrice/specialPriceSlice";
import partnerRequestReducer from "./slices/partnerRequest/partnerRequestSlice";
import companyRequestReducer from "./slices/companyRequest/companyRequestSlice";
import scriptReducer from "./slices/script/scriptSlice";
import lohnReducer from "./slices/lohn/lohnSlice";
import quotationReducer from "./slices/quotation/quotationSlice";
import companyAccountReducer from "./slices/companyAccount";
import privateBookingReducer from "./slices/privateBooking";
import priorityReducer from "./slices/priority/prioritySlice";
import invoiceBookingReducer from "./slices/invoiceBooking";
import availabiltiyReducer from "./slices/availability";
import { dynamicReducer } from "./slices/dynamics/dynamicSlice";
import smartDriver from "./slices/smartDriver";

const appReducer = combineReducers({
  form: formReducer,
  dashboard: dashboardReducer,
  quotation: quotationReducer,
  lohn: lohnReducer,
  auth: authReducer,
  user: userReducer,
  zone: zoneReducer,
  class: classReducer,
  modal: modalReducer,
  model: modelReducer,
  event: eventReducer,
  driver: driverReducer,
  loader: loaderReducer,
  career: careerReducer,
  contact: contactReducer,
  invoice: invoiceReducer,
  partner: partnerReducer,
  company: companyReducer,
  vehicle: vehicleReducer,
  pricing: pricingReducer,
  booking: bookingReducer,
  payment: paymentReducer,
  payroll: payRollReducer,
  campaign: campaignReducer,
  priority: priorityReducer,
  commission: commissionReducer,
  formLoader: formLoaderReducer,
  notification: notificationReducer,
  searchHistory: searchHistoryReducer,
  companyInvoice: companyInvoiceReducer,
  companyRequest: companyRequestReducer,
  partnerRequest: partnerRequestReducer,
  reportedProblem: reportedProblemReducer,
  product: productReducer,
  timeZone: timeZoneReducer,
  currency: currencyReducer,
  role: roleReducer,
  specialprice: specialPriceReducer,
  smart: smartReducer,
  script: scriptReducer,
  nightPrice: nightPriceReducer,
  companyAccount: companyAccountReducer,
  privateBooking: privateBookingReducer,
  invoiceBooking: invoiceBookingReducer,
  dynamics: dynamicReducer,
  availability: availabiltiyReducer,
  smartDriver: smartDriver,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === "auth/logout") state = {};
  return appReducer(state, action);
};

export default rootReducer;
